export function redirectPost(postUrl, data) {
    if (typeof postUrl !== "string") {
        throw `Parameter "postUrl" should be type "string", instead received ${typeof postUrl}`;
    }

    if (typeof data !== "object") {
        throw `Parameter "data" should be type "object", instead received ${typeof data}`;
    }

    const form = document.createElement("form");
    document.body.appendChild(form);
    form.method = "post";
    form.action = postUrl;
    for (const field of Object.keys(data)) {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = field;
        input.value = data[field];
        form.appendChild(input);
    }
    form.submit();
}
