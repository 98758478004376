import { docCookies } from "ReactApi/docCookies";

function isLoggedIn() {
    var cookieVal = docCookies.getItem("authenticationToken"); //window.document.cookie.replace(/(?:(?:^|.*;\s*)authenticationToken\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    return cookieVal && cookieVal != "";
}

export function isAuthorized(isRecursive) {
    return new Promise(resolve => {
        var checkCookies;
        checkCookies = function () {
            if (isLoggedIn()) {
                resolve(true);
            } else {
                if (isRecursive) setTimeout(checkCookies, 500);
                else resolve(false);
            }
        };
        checkCookies();
    });
}

export function isAuthorizedChanged(currentCookieValue, isRecursive) {
    return new Promise(resolve => {
        var checkCookies;
        checkCookies = function () {
            if (currentCookieValue != docCookies.getItem("authenticationToken")) {
                resolve(true);
            } else {
                if (isRecursive) setTimeout(checkCookies, 500);
                else resolve(false);
            }
        };
        checkCookies();
    });
}

export function getAuthProvider() {
    if (!window) return;
    return window.document.cookie.replace(/(?:(?:^|.*;\s*)currentLoginProvider\s*=\s*([^;]*).*$)|^.*$/, "$1");
}
