import { DeepPropertyMap } from "types";

type HttpMethod = "GET" | "POST" | "PUT" | "DELETE";

export interface SitecoreResponse<T> {
    ErrorCode: string;
    Payload: T;
    ErrorMessage: string;
    ErrorMessageRaw: string | null;
    Success: boolean;
}

export async function callServer<T, K = unknown>(url: string, method: HttpMethod, data?: K) {
    url = getFullUrl(url);

    // return {
    //     ErrorCode: "101",
    //     ErrorMessage: "Shit went wrong",
    //     ErrorMessageRaw: null,
    //     Payload: data,
    //     Success: false
    // } as unknown as SitecoreResponse<T>;

    const fetchConfig: RequestInit = {
        method: method.toUpperCase()
    };

    if (fetchConfig.method === "POST") {
        fetchConfig.headers = {
            Accept: "application/json",
            "Content-Type": "application/json"
        };
        fetchConfig.credentials = "include";
        fetchConfig.body = JSON.stringify(data);
    }

    const response = await fetch(url, fetchConfig);
    const json = await response.json();

    return json as SitecoreResponse<T>;

    // return new Promise((resolve, reject) => {
    //     return fetch(url, fetchConfig)
    //         .then(response => {
    //             return response.json() as unknown as SitecoreResponse<T>;
    //         })
    //         .then(res => {
    //             resolve(res);
    //         })
    //         .catch(error => {
    //             reject(error);
    //         });
    // });
}

function getFullUrl(url: string) {
    // if (!window.location.origin) {
    //     window.location.origin =
    //         window.location.protocol +
    //         "//" +
    //         window.location.hostname +
    //         (window.location.port ? ":" + window.location.port : "");
    // }

    return document.location.origin + "/" + url;
}

export type SiteCoreErrors<T> = DeepPropertyMap<T, string | undefined>;
