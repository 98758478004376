import { fork, select, put, call, takeEvery, takeLatest, delay, all } from "redux-saga/effects";
import { callServer } from "../../ReactApi/serverService";
import { productListSelector } from "./product-list-reducer";
import { productListActions } from "./product-list-reducer";
import { errorActions } from "../Error/error-reducer";
import { searchSelector } from "../Search/search-reducer";

function* selectFacets() {
    yield put(productListActions.fetchProductList());
}

function* removeFacets() {
    yield put(productListActions.fetchProductList());
}

function* updateList() {
    yield put(productListActions.closeFacetOverlay());
    yield put(productListActions.fetchProductList());
}

function* sortList() {
    yield put(productListActions.fetchProductList());
}

function* fetchProductList() {
    const isOverlayActive = yield select(productListSelector.isFilterOverLayActive);

    if (!isOverlayActive) {
        try {
            const res = yield call(pullProducts, 0);
            if (res.Success) {
                yield put(productListActions.fetchProductListSuccess(res.Payload));
            } else {
                yield put(productListActions.fetchProductListError(res));
            }

            yield delay(250);
        } catch (err) {
            yield put(productListActions.fetchProductListError(err));
        } finally {
            yield put(productListActions.fetchProductListComplete());
        }
    } else {
        yield put(productListActions.fetchProductListComplete());
    }
}

function* fetchMoreProducts() {
    try {
        const res = yield call(pullProducts);

        if (res.Success) {
            yield put(productListActions.fetchMoreProductsSuccess(res.Payload.Works));
        } else {
            yield put(errorActions.addError(res.errorMessage));
        }
    } catch (err) {
        yield put(errorActions.addError(err));
    } finally {
        yield put(productListActions.fetchMoreProductsComplete());
    }
}

function* pullProducts() {
    const contextId = yield select(productListSelector.ContextId);
    const facetConfigurationId = yield select(productListSelector.FacetConfigurationId);
    const listUrl = yield select(productListSelector.ListUrl);
    const initialFilter = yield select(productListSelector.InitialFilter);
    const enabledFacets = yield select(productListSelector.FacetGroupsActive);
    const sorting = yield select(productListSelector.Sorting);
    const currentPage = yield select(productListSelector.CurrentPage);
    const pageSize = yield select(productListSelector.PageSize);
    const query = yield select(searchSelector.query);

    yield delay(350);

    return yield call(callServer, "/WebAPI/Feature/Products/Product/FilterProductList", "POST", {
        ContextId: contextId,
        facetConfigurationId: facetConfigurationId,
        initialFilter: initialFilter,
        listUrl: listUrl,
        enabledFacets: enabledFacets,
        orderBy: sorting.orderBy,
        orderDir: sorting.orderDir,
        page: currentPage,
        pageSize: pageSize,
        search: query
    });
}

export default function* productSaga() {
    yield all([
        fork(function* () {
            yield takeLatest("SELECT_FACETS", selectFacets);
        }),
        fork(function* () {
            yield takeEvery("REMOVE_FACETS", removeFacets);
        }),
        fork(function* () {
            yield takeEvery("UPDATE_PRODUCTLIST", updateList);
        }),
        fork(function* () {
            yield takeEvery("PRODUCTLIST_SORT", sortList);
        }),
        fork(function* () {
            yield takeEvery("FETCH_MORE_PRODUCTS", fetchMoreProducts);
        }),
        fork(function* () {
            yield takeEvery("FETCH_PRODUCTLIST", fetchProductList);
        })
    ]);
}
