import React, { Component } from "react";

class IconSearch extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="15" cy="15" r="8" stroke="currentColor" strokeWidth="2" />
                <path
                    d="M20.6602 20.8652L25.317 25.5221"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                />
            </svg>
        );
    }
}

export default IconSearch;
