// @ts-check
import { articleTeaserListActions } from "@/ReactReducers/ArticleTeaserList/article-teaser-list-reducer";
import { defineView } from "@/ReactSetup/view-definitions";

const definition = defineView({
    name: "ArticleArchive",
    component: () => (__SERVER__ ? require("./article-archive-view") : import("./article-archive-view")),
    setup: payload => {
        return articleTeaserListActions.init(payload);
    }
});

export default definition;
