export const browserInfo = function browserInfo() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ");
    const edge = ua.indexOf("Edge/");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) {
        // If Internet Explorer, return version number
        //alert(parseInt(ua.substring(msie + 5, ua.indexOf(".", msie))));
        return { name: "IE", version: undefined };
    } else if (edge != -1) {
        return { name: "EDGE", version: undefined };
    } // If another browser, return 0
    else {
        //alert('otherbrowser');
        return { name: "notIE", version: undefined };
    }
};
