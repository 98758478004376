import { fork, put, call, takeEvery, takeLatest, delay, all } from "redux-saga/effects";
import { errorActions } from "../Error/error-reducer";
import { callServer } from "ReactApi/serverService";
import { newsletterActions } from "./newsletter-reducer";
import { subscribeToNewsletterTracking } from "../../ReactComponents/Shared/Utils/tracking";

function* newsletterSignup(action) {
    try {
        const data = action.data;
        const url = action.apiUrl || "/WebAPI/Feature/Profile/SubscribeNewsletter";
        const res = yield callServer(url, "POST", data);

        if (res.Success) {
            yield call(subscribeToNewsletterTracking);
            yield put(newsletterActions.newsletterSignupSuccess());
            action.resolve(res);
        } else {
            action.resolve(res);
            yield put(newsletterActions.newsletterSignupError());
        }
    } catch (error) {
        yield put(errorActions.addError(error.message));
        yield put(newsletterActions.newsletterSignupError());
        action.reject();
    } finally {
        yield put(newsletterActions.newsletterSignupComplete());
    }
}

function* newsletterSettingsSave(action) {
    try {
        yield delay(500);
        const data = action.data;
        const url = action.apiUrl || "/WebAPI/Feature/Profile/SaveNewsletterProfile";
        const res = yield callServer(url, "POST", data);

        if (res.Success) {
            yield put(newsletterActions.newsletterSettingsSaveSuccess());
            action.resolve(res);
        } else {
            action.resolve(res);
            yield put(newsletterActions.newsletterSettingsSaveError());
        }
    } catch (error) {
        yield put(errorActions.addError(error.message));
        yield put(newsletterActions.newsletterSettingsSaveError());
        action.reject();
    } finally {
        yield put(newsletterActions.newsletterSettingsSaveComplete());
    }
}

export default function* newsletterSaga() {
    yield all([
        fork(function* () {
            yield takeLatest("NEWSLETTER_SIGNUP", newsletterSignup);
        }),
        fork(function* () {
            yield takeEvery("NEWSLETTER_SETTINGS_SAVE", newsletterSettingsSave);
        })
    ]);
}
