import { fork, select, put, call, takeLatest, delay, all } from "redux-saga/effects";
import { callServer } from "ReactApi/serverService";
import { searchSelector, searchActions } from "ReactReducers/Search/search-reducer";
import { productListActions } from "ReactReducers/ProductList/product-list-reducer";
import { errorActions } from "../Error/error-reducer";

function* searchRun(action) {
    if (!action.propsObj.DisableSiteSearch) {
        yield put(searchActions.contentSearchSubmit());
    }

    yield delay(100); //this timeout is to make sure the productlist has initialized...
    const query = yield select(searchSelector.query);
    const lastSubmittedQuery = yield select(searchSelector.lastSubmittedQuery);

    if (query != lastSubmittedQuery && lastSubmittedQuery != "") {
        yield put(productListActions.removeFacets());
    } else {
        yield put(productListActions.fetchProductList());
    }

    yield put(searchActions.setSubmittedQuery());
}

function* contentSearchRun() {
    try {
        var query = yield select(searchSelector.query);

        if (!query || query == "") {
            return;
        }

        var page = yield select(searchSelector.contentSearchPage);
        var pageSize = yield select(searchSelector.contentSearchPageSize);

        var res = yield call(
            callServer,
            "/WebAPI/Feature/Search/SiteSearch?query=" + query + "&page=" + page + "&pageSize=" + pageSize,
            "GET"
        );

        if (res.Success) {
            yield put(searchActions.contentSearchSuccess(res.Payload));
        } else {
            yield put(errorActions.addError(res.ErrorMessageRaw));
        }
    } finally {
        yield put(searchActions.searchComplete());
    }
}

function* productSearchRun() {
    // not baeing used at the moment
    try {
        var query = yield select(searchSelector.query);

        if (!query || query == "") return;

        var page = yield select(searchSelector.productSearchPage);

        var res = yield call(
            callServer,
            "/WebAPI/Feature/Products/Product/SearchWorks?query=" + query + "&page=" + page,
            "GET"
        );

        if (res.Success) {
            yield put(searchActions.productSearchSuccess(res.Payload));
        } else {
            yield put(searchActions.productSearchError(res.ErrorMessage));
        }
    } finally {
        yield put(searchActions.searchComplete());
    }
}

export default function* searchSaga() {
    yield all([
        fork(function* () {
            yield takeLatest("INIT_SEARCH", searchRun);
        }),
        fork(function* () {
            yield takeLatest("SEARCH_SUBMIT", searchRun);
        }),
        fork(function* () {
            yield takeLatest("CONTENT_SEARCH_SUBMIT", contentSearchRun);
        }),
        fork(function* () {
            yield takeLatest("CONTENT_SEARCH_PREVIOUS", contentSearchRun);
        }),
        fork(function* () {
            yield takeLatest("CONTENT_SEARCH_NEXT", contentSearchRun);
        }),
        fork(function* () {
            yield takeLatest("PRODUCT_SEARCH_SUBMIT", productSearchRun);
        }),
        fork(function* () {
            yield takeLatest("PRODUCT_SEARCH_PREVIOUS", productSearchRun);
        }),
        fork(function* () {
            yield takeLatest("PRODUCT_SEARCH_NEXT", productSearchRun);
        })
    ]);
}
