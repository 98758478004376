import * as React from "react";
import { useAppDispatch, useAppSelector } from "hooks/store-hooks";
import { profileActions, profileSelector } from "ReactReducers/Profile/profile-reducer";
import { Item, List } from "../navigation-profile/navigation-profile";
import { ICreateProfileModalProps } from "../../CreateProfile/CreateProfileModal/create-profile-modal";
import { SiteName } from "../../../types";
import { navigationSelector } from "ReactReducers/Navigation/navigation-reducer";

export interface INavigationMobileProfileProps {
    labels: ICreateProfileModalProps["labels"];
    siteName: SiteName;
}

export function NavigationMobileProfile() {
    const isLoggedIn = useAppSelector(profileSelector.loggedIn);

    return isLoggedIn ? <LoggedIn /> : <NotLoggedIn />;
    // return isLoggedIn ? <LoggedIn /> : <NotLoggedIn siteName={props.siteName} labels={props.labels} />;
}

export function LoggedIn() {
    const userProfile = useAppSelector(profileSelector.userInfo);
    const login = useAppSelector(profileSelector.login);

    if (!userProfile) {
        return null;
    }

    return (
        <List className="navigation-mobile-profile">
            <>
                {userProfile.ProfileLinks?.map((link, index) => {
                    return <Item className="navigation-menu-item" key={index} {...link} />;
                })}
                {login && <Item Text={login.LogoutUrl?.Text} Url={login.LogoutUrl?.FullUrl} />}
            </>
        </List>
    );
}

// interface INotLoggedInProps {
//     labels: ICreateProfileModalProps["labels"];
//     siteName: SiteName;
// }

export function NotLoggedIn() {
    const isOpen = useAppSelector(navigationSelector.isMobileMenuOpen);
    const dispatch = useAppDispatch();
    const login = useAppSelector(profileSelector.login);
    // const [isCreateProfileOpen, setIsCreateProfileOpen] = React.useState(false);

    if (!login) {
        return null;
    }

    return (
        <>
            <List className="navigation-mobile-profile">
                {login.LoginUrl && (
                    <Item
                        className="navigation-menu-item"
                        onClick={() => dispatch(profileActions.userLogin())}
                        Text={login.LoginUrl.Text}
                        isOpen={isOpen}
                    />
                )}
                {/* {props.siteName === "gu" ? (
                    <Item
                        className="navigation-menu-item"
                        Text={login.CreateProfileUrl.Text}
                        onClick={() => setIsCreateProfileOpen(true)}
                    />
                ) : ( */}
                <Item className="navigation-menu-item" {...login.CreateProfileUrl} />
                {/* )} */}
            </List>
            {/* {props.siteName === "gu" && (
                <CreateProfileModal
                    isOpen={isCreateProfileOpen}
                    onClose={() => setIsCreateProfileOpen(false)}
                    labels={props.labels}
                />
            )} */}
        </>
    );
}
