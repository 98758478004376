import React from "react";
import classNames from "classnames";
import IconInformation from "ReactComponents/Shared/Icons/icon-information";

export interface IFormSubmitErrorProps {
    error: string;
}

const FormSubmitError = (props: IFormSubmitErrorProps) => {
    const baseClass = "form-submit-error";
    const rootClasses = classNames(baseClass, "content-module");

    return (
        <div className={rootClasses}>
            <span className="form-submit-error__icon">
                <IconInformation />
            </span>
            <span className="form-submit-error__inner">{props.error}</span>
        </div>
    );
};

export default FormSubmitError;
