import { fromJS } from "immutable";

export const checkoutActions = {
    invoiceBegin: data => {
        return { type: "CHECKOUT_INVOICE_BEGIN", data };
    },
    creditCardBegin: (data, url, paymentProvider) => {
        return { type: "CHECKOUT_CREDITCARD_BEGIN", data, url, paymentProvider };
    },
    creditCardSubmit: data => {
        return { type: "CHECKOUT_CREDITCARD_SUBMIT", data };
    },
    checkoutComplete: () => {
        return { type: "CHECKOUT_COMPLETE" };
    },
    checkoutSubmitUserInfo: (data, promiseMethods) => {
        return { type: "CHECKOUT_SUBMIT_USER_INFO", data, promiseMethods };
    }
};

const init = {
    checkoutSubmitPending: false
};

//Reducer
export const checkoutReducer = function (state = fromJS(init), action) {
    switch (action.type) {
        case "CHECKOUT_SUBMIT_USER_INFO":
            return state.set("checkoutSubmitPending", true);

        case "CHECKOUT_INVOICE_BEGIN":
            return state.set("checkoutSubmitPending", true);

        case "CHECKOUT_CREDITCARD_BEGIN":
            return state.set("checkoutSubmitPending", true);

        case "CHECKOUT_CREDITCARD_SUBMIT":
            return state;

        case "CHECKOUT_COMPLETE":
            return state.set("checkoutSubmitPending", false);

        default:
            return state;
    }
};

export const checkoutSelector = {
    checkoutSubmitPending: state => state.checkout.get("checkoutSubmitPending")
};

export default checkoutReducer;
