import { fromJS } from "immutable";

export const productPickerActions = {
    setWork: work => {
        return { type: "PRODUCT_PICKER_SET_WORK", work };
    },
    setProductIndex: productIndex => {
        return { type: "PRODUCT_PICKER_SET_PRODUCT", productIndex };
    },
    setAmount: quantity => {
        return { type: "PRODUCT_PICKER_SET_AMOUNT", quantity };
    },
    getUpdatedWorkProducts: workId => {
        return { type: "PRODUCT_PICKER_GET_UPDATED_WORK_PRODUCTS", workId };
    },
    getProductPriceComplete: () => {
        return { type: "PRODUCT_PICKER_GET_PRICE_COMPLETE" };
    },
    setSalesConfigIndex: index => {
        return { type: "PRODUCT_PICKER_SET_SALES_CONFIG_INDEX", index };
    },
    setPeriodValueIndex: index => {
        return { type: "PRODUCT_PICKER_SET_PERIODVALUES_CONFIG_INDEX", index };
    },
    setClassesValue: classesValue => {
        return { type: "PRODUCT_PICKER_SET_CLASSES_VALUE", classesValue };
    }
};

//The adding threads are all controlled in arrays.
const init = {
    work: null,
    productIndex: 0,
    amount: null,
    salesConfigIndex: null,
    primaryPrice: "0,00",
    secondaryPrice: "0,00",
    isGettingProductPrice: false,
    periodValueIndex: 0,
    classesValue: []
};

//Reducer
const productPickerReducer = function (state = fromJS(init), action) {
    switch (action.type) {
        case "PRODUCT_PICKER_SET_WORK":
            return state.set("work", action.work);

        case "PRODUCT_PICKER_SET_AMOUNT":
            return state.set("amount", action.quantity).set("isGettingProductPrice", true);

        case "PRODUCT_PICKER_SET_PRODUCT": {
            const p = action.productIndex; //only index in works
            let amount = 1;
            let periodIndex = 0;

            if (state.get("work")) {
                const product = state.get("work").Products[action.productIndex];
                const pConfig = product.PriceConfigurations.filter(conf => {
                    return conf.Selected ? conf : false;
                })[0];
                if (pConfig && pConfig.AllowQuantity) {
                    amount = pConfig.Quantity;
                }

                if (pConfig && pConfig.PeriodOptions.length > 1) {
                    let selectedPeriodIndex = pConfig.PeriodOptions.map((obj, idx) => {
                        obj.idx = idx;
                        return obj;
                    });
                    selectedPeriodIndex = selectedPeriodIndex.filter(obj => {
                        return obj.Selected;
                    });
                    if (selectedPeriodIndex[0] && selectedPeriodIndex[0].idx) {
                        periodIndex = selectedPeriodIndex[0].idx;
                    }
                }
            }

            return state.set("productIndex", p).set("periodValueIndex", periodIndex).set("amount", amount);
        }

        case "PRODUCT_PICKER_SET_SALES_CONFIG_INDEX":
            return state.set("salesConfigIndex", action.index);

        case "PRODUCT_PICKER_SET_PERIODVALUES_CONFIG_INDEX":
            return state.set("isGettingProductPrice", true).set("periodValueIndex", action.index);

        case "PRODUCT_PICKER_SET_CLASSES_VALUE":
            return state.set("isGettingProductPrice", true).set("classesValue", action.classesValue);

        case "PRODUCT_PICKER_GET_UPDATED_WORK_PRODUCTS":
            return state;

        case "PRODUCT_PICKER_GET_PRICE_COMPLETE":
            return state.set("isGettingProductPrice", false);

        default:
            return state;
    }
};

export default productPickerReducer;

export const productPickerSelector = {
    work: state => state.productPicker.get("work"),
    productIndex: state => state.productPicker.get("productIndex"),
    basketProductConfig: state => state.productPicker.get("basketProductConfig"),
    amount: state => state.productPicker.get("amount"),
    salesConfigIndex: state => state.productPicker.get("salesConfigIndex"),
    periodValueIndex: state => state.productPicker.get("periodValueIndex"),
    classesValue: state => state.productPicker.get("classesValue"),
    primaryPrice: state => state.productPicker.get("primaryPrice"),
    secondaryPrice: state => state.productPicker.get("secondaryPrice"),
    isGettingProductPrice: state => state.productPicker.get("isGettingProductPrice")
};
