// @ts-check
export function defineView(props) {
    return {
        ...props,
        component: props.component
    };
}

/**
 * When creating the server-bundle, all async dynamic imports (`const c = import("pathToFile")`)
 * are converted to synchronous requires (`const c = require("pathToFile")`).
 * This is needed, because the server side renderer doesn't wait for promises to
 * be fulfilled.
 * The conversion happens in the "transform-import-to-require" babel plugin.
 */
export function isAsyncComponent(_component) {
    return !__SERVER__;
}

function getViewDefinitions() {
    const req = require.context("@/ReactViews", true, /-definition\.js$/);
    const definitions = req
        .keys()
        .filter(path => req(path).default)
        .map(path => req(path).default);
    return definitions;
}

function viewDefinitions() {
    const definitions = getViewDefinitions();

    const views = {};
    definitions.forEach(definition => {
        views[definition.name] = definition;
    });

    return views;
}

export function findViewDefinition(name) {
    const definitions = viewDefinitions();
    const match = Object.keys(definitions).find(viewName => viewName === name);
    return match ? definitions[match] : undefined;
}

export default viewDefinitions;
