import { fork, select, put, call, takeEvery, all } from "redux-saga/effects";
import { callServer } from "../../ReactApi/serverService";
import { systemListSelector, systemListActions } from "./system-list-reducer";

function* initFetchSystems() {
    const res = yield fetchSystems();

    if (res.Success) {
        yield put(systemListActions.systemsLoadSuccess(res.Payload));
    } else {
        yield put(systemListActions.systemsLoadError(res.ErrorMessage, 3500));
    }
}

function* systemsLoadMore() {
    const res = yield fetchSystems();

    if (res.Success) {
        yield put(systemListActions.systemsLoadMoreSuccess(res.Payload));
    } else {
        yield put(systemListActions.systemsLoadError(res.ErrorMessage, 3500));
    }
}

function* systemsSort() {
    const res = yield fetchSystems();

    if (res.Success) {
        yield put(systemListActions.systemsLoadSuccess(res.Payload));
    } else {
        yield put(systemListActions.systemsLoadError(res.ErrorMessage, 3500));
    }
}

function* fetchSystems() {
    const page = yield select(systemListSelector.page);
    const pageSize = yield select(systemListSelector.pageSize);
    const itemId = yield select(systemListSelector.itemId);
    const sortBy = yield select(systemListSelector.orderDir);
    const systemId = yield select(systemListSelector.systemId);

    return yield call(
        callServer,
        "/WebAPI/Feature/Products/System/GetSystems?itemId=" +
            itemId +
            "&systemId=" +
            systemId +
            "&page=" +
            page +
            "&pageSize=" +
            pageSize +
            "&sortBy=" +
            sortBy,
        "GET"
    );
}

export default function* systemListSaga() {
    yield all([
        fork(function* () {
            yield takeEvery("SYSTEMS_INIT", initFetchSystems);
        }),
        fork(function* () {
            yield takeEvery("SYSTEMS_LOAD_MORE", systemsLoadMore);
        }),
        fork(function* () {
            yield takeEvery("SYSTEMS_SORT", systemsSort);
        })
    ]);
}
