import { fromJS } from "immutable";

//Actions
export const updatePaymentActions = {
    updatePaymentRequest: paymentProvider => {
        return { type: "UPDATE_PAYMENT_REQUEST", paymentProvider };
    },
    updatePaymentSuccess: () => {
        return { type: "UPDATE_PAYMENT_SUCCESS" };
    },
    updatePaymentError: () => {
        return { type: "UPDATE_PAYMENT_ERROR" };
    },
    updatePaymentComplete: () => {
        return { type: "UPDATE_PAYMENT_COMPLETE" };
    },
    updatePaymentSubmit: Payload => {
        return { type: "UPDATE_PAYMENT_SUBMIT", Payload };
    }
};

//Reducer initial state
const init = {
    pending: false,
    success: false,
    error: false,
    updateRequest: {}
};

//Reducer
const updatePaymentReducer = function (state = fromJS(init), action) {
    switch (action.type) {
        case "UPDATE_PAYMENT_REQUEST":
            return state.set("pending", true);

        case "UPDATE_PAYMENT_SUCCESS":
            return state.set("success", true);

        case "UPDATE_PAYMENT_ERROR":
            return state.set("error", true);

        case "UPDATE_PAYMENT_COMPLETE":
            return state.set("pending", false);

        default:
            return state;
    }
};

//Selector
export const updatePaymentSelector = {
    updatePaymentPending: state => state.updatePayment.get("pending")
};

export default updatePaymentReducer;
