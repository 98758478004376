import viewFinder from "@/ReactSetup/view-finder";
import viewMounter from "@/ReactSetup/view-mounter";
import { debounce } from "../js/utils/debounce";
import { redirectPost } from "../js/utils/redirect";

function mountViews() {
    const components = viewFinder();
    components.forEach(viewMounter);
}

var siteNames = {
    gu: "gu",
    munksgaard: "munksgaard",
    hansreitzel: "hansreitzel",
    elearning: "elearning"
};

global.siteThemeName = siteNames.gu;

export function clientInitializer() {
    global.browser = require("@/ReactComponents/Shared/Utils/browser-sniff").browserInfo();

    // TODO: Try to remove window checks, should be guaranteed
    if (window) {
        var name = window.location.hostname;
        if (name.indexOf(siteNames.munksgaard) != -1) {
            siteThemeName = siteNames.munksgaard;
        }
        if (name.indexOf(siteNames.hansreitzel) != -1) {
            siteThemeName = siteNames.hansreitzel;
        }
        if (name.indexOf(siteNames.elearning) != -1) {
            global.siteThemeName = siteNames.elearning;
        }
    }

    // Code for intercepting Sleeknote signup event and redirecting
    // The code utilizes a proxy, which is instantiated just before the gtm script is called (added in sitecore editor)
    const getGtmEventByEventId = (function () {
        // Store the number of events checked so that we can avoid checking them on subsequent calls
        let eventsChecked = 0;

        return function (eventId) {
            if (typeof window._dataLayer === "undefined") {
                return;
            }

            // Check gtm events in reverse order, stopping once we reach the events already checked
            for (let i = window._dataLayer.length - 1; i >= eventsChecked; i--) {
                const gtmEvent = window._dataLayer[i];

                // Return the event if the id matches
                if (gtmEvent.event === eventId) {
                    return gtmEvent;
                }
            }

            eventsChecked = window._dataLayer.length;
        };
    })();

    const onGtmEventPushed = eventId => {
        const event = getGtmEventByEventId(eventId);

        // Ensure that an event with a matching id exists, and that it has the correct structure
        if (event !== undefined && event.fieldData !== undefined) {
            const name = event.fieldData.name;
            const email = event.fieldData.email;

            if (!name || !email) {
                return;
            }

            // Redirect to signup page with the name and email as post params
            redirectPost(`${window.location.origin}/newsletter/NewsletterValidateEmailPopUp`, {
                name,
                email
            });
        }
    };

    if (typeof window !== undefined) {
        // Expose a callback in the global object for when the dataLayer is modified
        // gtm events are occasionally pushed at a high frequency, so we debounce the callback
        window.gtmListenerCallback = debounce(() => {
            onGtmEventPushed("SleeknoteSubscriber", 2000);
        }, 500);
    }

    document.addEventListener("DOMContentLoaded", () => mountViews());
}
