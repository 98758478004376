import React from "react";

const IconInformation = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="11" cy="11" r="10" stroke="currentColor" strokeWidth="2" />
            <path d="M11 10L11 16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
            <circle cx="11" cy="6" r="1" fill="currentColor" />
        </svg>
    );
};

export default IconInformation;
