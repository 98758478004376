import { fromJS } from "immutable";

export const contactFormActions = {
    contactFormSubmit: (webApiUrl, formInfo, resolve, reject) => {
        return { type: "CONTACT_FORM_SUBMIT", webApiUrl, formInfo, resolve, reject };
    },
    contactFormSubmitError: formInfo => {
        return { type: "CONTACT_FORM_ERROR", formInfo };
    },
    contactFormSubmitSuccess: formInfo => {
        return { type: "CONTACT_FORM_SUCCESS", formInfo };
    }
};

const init = {
    contactFormSubmitPending: false,
    contactFormSubmitSuccess: false
};

//Reducer
const contactFormReducer = function (state = fromJS(init), action) {
    switch (action.type) {
        case "CONTACT_FORM_SUBMIT":
            return state.set("contactFormSubmitPending", true);

        case "CONTACT_FORM_ERROR":
            return state.set("contactFormSubmitPending", false);

        case "CONTACT_FORM_SUCCESS":
            return state.set("contactFormSubmitPending", false).set("contactFormSubmitSuccess", true);

        default:
            return state;
    }
};

export const contactFormSelector = {
    contactFormSubmitPending: state => state.contactForm.get("contactFormSubmitPending"),
    contactFormSubmitSuccess: state => state.contactForm.get("contactFormSubmitSuccess")
};

export default contactFormReducer;
