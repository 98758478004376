import { fork, select, put, call, takeLatest, all } from "redux-saga/effects";
import { callServer } from "../../ReactApi/serverService";
import { productPickerActions as actions } from "./product-picker-reducer";
import { errorActions } from "ReactReducers/Error/error-reducer";

function* getProductConfig() {
    let state = yield select();
    state = state.productPicker;
    const configIndex = state.get("salesConfigIndex") || 0;
    const periodIndex = state.get("periodValueIndex") || 0;
    const work = state.get("work");
    const productIndex = state.get("productIndex");
    const product = work.Products[productIndex];
    const amount = state.get("amount");

    return {
        Id: product.Id,
        Isbn: product.Isbn13,
        Count: product.PriceConfigurations[configIndex].AllowQuantity ? amount : 1,
        SalesFormCode: product.PriceConfigurations[configIndex].SalesFormCode,
        AccessFormCode: product.PriceConfigurations[configIndex].AccessFormCode,
        PeriodUnitValue: product.PriceConfigurations[configIndex].PeriodOptions[periodIndex].UnitValue,
        PeriodUnitTypeCode: product.PriceConfigurations[configIndex].PeriodOptions[periodIndex].UnitTypeCode,
        Classes: state.get("classesValue")
    };
}

function* getUpdateWorkProducts(action) {
    try {
        const response = yield call(callServer, "/WebAPI/Feature/Products/Product/GetWorkPrice", "POST", {
            WorkId: action.workId
        });

        if (response.Success) {
            const state = yield select();
            const productPickerState = state.productPicker;

            const work = productPickerState.get("work");
            const newWork = JSON.parse(JSON.stringify(work));
            newWork.Products = newWork.Products.map(product => {
                const matchProduct = response.Payload.Products.filter(
                    responseProduct => responseProduct.ProductId == product.Id
                )[0];

                product.IsInBasket = matchProduct.IsInBasket;
                product.PriceConfigurations = matchProduct.PriceConfigurations;
                return product;
            });

            yield put(actions.setWork(newWork));
        } else {
            yield put(errorActions.addError(response.ErrorMessage));
        }
    } catch (error) {
        yield put(errorActions.addError());
    }
}

function* setAmount() {
    yield getProductPrice();
}
function* setClasses() {
    yield getProductPrice();
}
function* setPeriodValues() {
    yield getProductPrice();
}

function* getProductPrice() {
    try {
        const config = yield getProductConfig();
        const response = yield call(
            callServer,
            "/WebAPI/Feature/Products/Product/GetProductPrice",
            "POST",
            config
        );

        if (response.Success) {
            const state = yield select();
            const productPickerState = state.productPicker;

            const work = productPickerState.get("work");
            const newWork = JSON.parse(JSON.stringify(work));
            newWork.Products = newWork.Products.map((product, index) => {
                if (index == productPickerState.get("productIndex")) {
                    product.IsInBasket = Boolean(response.Payload.IsInBasket);
                    product.PriceConfigurations = response.Payload.PriceConfigurations;
                }
                return product;
            });

            yield put(actions.setWork(newWork));
        } else {
            yield put(errorActions.addError(response.ErrorMessage));
        }
    } catch (error) {
        yield put(errorActions.addError(error.message));
    } finally {
        yield put(actions.getProductPriceComplete());
    }
}

export default function* productPickerSaga() {
    yield all([
        fork(function* () {
            yield takeLatest("PRODUCT_PICKER_GET_UPDATED_WORK_PRODUCTS", getUpdateWorkProducts);
        }),
        fork(function* () {
            yield takeLatest("PRODUCT_PICKER_SET_AMOUNT", setAmount);
        }),
        fork(function* () {
            yield takeLatest("PRODUCT_PICKER_SET_PERIODVALUES_CONFIG_INDEX", setPeriodValues);
        }),
        fork(function* () {
            yield takeLatest("PRODUCT_PICKER_SET_CLASSES_VALUE", setClasses);
        })
    ]);
}
