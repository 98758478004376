import classNames from "classnames";
import * as React from "react";
import { useDispatch } from "react-redux";
import IconSearch from "ReactComponents/Shared/Icons/icon-search";
import { navigationActions } from "ReactReducers/Navigation/navigation-reducer";

interface IInlineSearchProps {
    Placeholder: string;
    className?: string;
    open?: boolean;
}

export const InlineSearch = React.forwardRef(
    (props: IInlineSearchProps, ref: React.ForwardedRef<HTMLInputElement>) => {
        const [isRedirecting, setIsRedirecting] = React.useState(false);
        const dispatch = useDispatch();

        function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
            if (e.key === "Escape") {
                dispatch(navigationActions.toggleSearch({}));
                (document.querySelector(".navigation-header__button") as HTMLElement)?.focus();
            }

            if (e.key !== "Enter" && e.key !== "Escape") {
                return;
            }

            setIsRedirecting(true);
            window.location.href = "/soeg?q=" + e.currentTarget.value;
        }

        return (
            <div
                className={classNames("inline-search content-module", props.className, {
                    "inline-search--isRedirecting": isRedirecting
                })}
            >
                <div className="content-row">
                    <div className="inline-search__input-wrapper">
                        <IconSearch />
                        <input
                            ref={ref}
                            disabled={!props.open}
                            onKeyDown={onKeyDown}
                            className="inline-search__input"
                            type="search"
                            role="searchbox"
                            placeholder={props.Placeholder}
                        />
                    </div>
                </div>
            </div>
        );
    }
);
