import * as React from "react";
import { navigationSelector, navigationActions } from "ReactReducers/Navigation/navigation-reducer";
import { INavigationList } from "./navigation";
import { useAppDispatch, useAppSelector } from "hooks/store-hooks";
import classNames from "classnames";
import FocusTrap from "ReactComponents/Trap/focus-trap";

interface INavigationMenuItemProps extends INavigationList {
    parentIndex?: number;
    index: number;
    isActive?: boolean;
    isShown?: boolean;
    onClick?(): void;
    enableTabIndexMobile?: boolean;
    enableTabIndexDesktop?: boolean;
    disabled?: boolean;
}

export function NavigationMenuItem(props: INavigationMenuItemProps) {
    const dispatch = useAppDispatch();

    const isMobileMenuOpen = useAppSelector(navigationSelector.isMobileMenuOpen);
    const isMenuOpen = useAppSelector(navigationSelector.isMenuOpen);

    const classes = classNames("navigation-menu-item", {
        "navigation-menu-item--isActive": props.isActive
    });
    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const anchorRef = React.useRef<HTMLAnchorElement>(null);

    function onMouseEnter() {
        if (window.innerWidth < 1240) {
            return;
        }

        props.onClick?.();
    }

    if (props.Children.length && props.parentIndex === undefined) {
        return (
            <li className={classes}>
                <button
                    ref={buttonRef}
                    onMouseEnter={onMouseEnter}
                    onClick={() => {
                        props.onClick?.();
                    }}
                    className="navigation-menu-item__button"
                    type="button"
                    disabled={props.disabled}
                >
                    <span className="navigation-menu-item__label">
                        {props.Label}

                        <svg
                            width="9"
                            height="14"
                            viewBox="0 0 9 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M1.5006 0.999884L7.15746 6.65674L1.5006 12.3136" stroke="currentColor" />
                        </svg>
                    </span>
                </button>
            </li>
        );
    }

    if (props.Url) {
        return (
            <li
                className={classes}
                onMouseEnter={
                    () =>
                        props.parentIndex === undefined ? dispatch(navigationActions.resetSubMenu()) : null //Added this check to prevent submenu items from resetting submenu
                }
            >
                {isMenuOpen && !isMobileMenuOpen ? (
                    <a
                        ref={anchorRef}
                        tabIndex={(props.isShown || props.isActive) && props.enableTabIndexDesktop ? 0 : -1}
                        href={props.Url}
                        className="navigation-menu-item__link"
                    >
                        <span className="navigation-menu-item__label">{props.Label}</span>
                    </a>
                ) : (
                    <a
                        ref={anchorRef}
                        tabIndex={(props.isShown || props.isActive) && props.enableTabIndexMobile ? 0 : -1}
                        href={props.Url}
                        className="navigation-menu-item__link"
                    >
                        <span className="navigation-menu-item__label">{props.Label}</span>
                    </a>
                )}
            </li>
        );
    }

    return null;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface INavigationMenuProps extends INavigationList {}

export function NavigationMenu(props: INavigationMenuProps) {
    const dispatch = useAppDispatch();
    const submenuIndex = useAppSelector(navigationSelector.activeSubMenu);
    const isMenuOpen = useAppSelector(navigationSelector.isMenuOpen);
    const activeMenu = useAppSelector(navigationSelector.activeMenu);
    const isMobileMenuOpen = useAppSelector(navigationSelector.isMobileMenuOpen);

    const onClick = (index: number) => {
        dispatch(navigationActions.setActiveSubMenu(index));

        // Meh
        setTimeout(() => {
            (
                document.querySelector(
                    `.navigation-submenu.navigation-submenu--0.navigation-submenu--isActive ul.navigation-submenu__items--${
                        isMobileMenuOpen ? "mobile" : "desktop"
                    } li:first-child a`
                ) as HTMLElement
            ).focus();
        }, 0);
    };

    const onEsc = () => {
        dispatch(navigationActions.setActiveMenu(undefined));
        dispatch(navigationActions.setActiveSubMenu(undefined));

        if (Number.isInteger(activeMenu)) {
            (
                document.querySelector(
                    `.navigation-header__items li:nth-child(${(activeMenu as number) + 1}) button`
                ) as HTMLElement
            ).focus();
        }
    };

    return (
        <FocusTrap onEsc={onEsc} active={isMenuOpen} className="navigation-menu-container">
            <span className="navigation-menu__title">{props.Label}</span>
            <div
                className={classNames("navigation-menu", {
                    "navigation-menu--submenuShown": submenuIndex !== undefined
                })}
            >
                <div className="navigation-menu__inner">
                    <ul className="navigation-menu__items">
                        {props.Children.map((item, index) => {
                            const isActive = index === submenuIndex;

                            return (
                                <NavigationMenuItem
                                    key={item.Id}
                                    {...item}
                                    index={index}
                                    isActive={isActive}
                                    isShown
                                    onClick={() => onClick(index)}
                                    enableTabIndexMobile={isMenuOpen && isMobileMenuOpen}
                                    enableTabIndexDesktop={isMenuOpen && !isMobileMenuOpen}
                                />
                            );
                        })}
                    </ul>
                </div>
            </div>
            {props.Children.map((submenu, index) => {
                return <NavigationSubmenu key={submenu.Id} {...submenu} isActive={index === submenuIndex} />;
            })}
        </FocusTrap>
    );
}

export function NavigationSubmenu(props: INavigationList & { isActive: boolean }) {
    const dispatch = useAppDispatch();
    const isMobileMenuOpen = useAppSelector(navigationSelector.isMobileMenuOpen);
    const isDesktopMenuOpen = useAppSelector(navigationSelector.isMenuOpen);

    // Split list into 2 equal lists if more than 10 items (desktop only)
    const lists = React.useMemo(() => {
        let lists: INavigationList[][] = [];
        if (props.Children.length > 10 && isDesktopMenuOpen && !isMobileMenuOpen) {
            lists = [props.Children.slice(0, 10), props.Children.slice(10)];
        } else {
            lists.push(props.Children);
        }
        return lists;
    }, [isDesktopMenuOpen, isMobileMenuOpen]);

    return (
        <>
            {lists.map((list, listIndex) => (
                <div
                    key={listIndex}
                    className={classNames("navigation-submenu", `navigation-submenu--${listIndex}`, {
                        "navigation-submenu--isActive": props.isActive
                    })}
                >
                    <span className="navigation-submenu__title">{props.Label}</span>
                    <ul className="navigation-submenu__items navigation-submenu__items--desktop">
                        {list.map((item, index) => {
                            return (
                                <NavigationMenuItem
                                    key={item.Id}
                                    {...item}
                                    isShown={props.isActive}
                                    index={index}
                                    parentIndex={listIndex}
                                    onClick={() => dispatch(navigationActions.setActiveSubMenu(index))}
                                    enableTabIndexDesktop={
                                        isDesktopMenuOpen && !isMobileMenuOpen && props.isActive
                                    }
                                />
                            );
                        })}
                    </ul>
                    <ul className="navigation-submenu__items navigation-submenu__items--mobile">
                        {props.Children.map((item, index) => {
                            return (
                                <NavigationMenuItem
                                    key={item.Id}
                                    {...item}
                                    isShown={props.isActive}
                                    index={index}
                                    parentIndex={listIndex}
                                    onClick={() => dispatch(navigationActions.setActiveSubMenu(index))}
                                    enableTabIndexMobile={isMobileMenuOpen && props.isActive}
                                />
                            );
                        })}
                    </ul>
                </div>
            ))}
        </>
    );
}
