import { fork, select, put, call, takeEvery, delay, all } from "redux-saga/effects";
import { callServer } from "../../ReactApi/serverService";
import { ordersSelector, ordersActions } from "./orders-reducer";
import { errorActions } from "../Error/error-reducer";

function* initFetchOrders() {
    const res = yield fetchOrders();

    if (res.Success) {
        yield put(ordersActions.ordersLoadSuccess(res.Payload));
    } else {
        yield put(ordersActions.ordersLoadError(res.ErrorMessage, 3500));
    }
}

function* ordersLoadMore() {
    const res = yield fetchOrders();

    if (res.Success) {
        yield put(ordersActions.ordersLoadMoreSuccess(res.Payload));
    } else {
        yield put(ordersActions.ordersLoadError(res.ErrorMessage, 3500));
    }
}

function* fetchOrders() {
    const itemId = yield select(ordersSelector.itemId);
    const page = yield select(ordersSelector.page);
    const pageSize = yield select(ordersSelector.pageSize);

    const orderRequestData = {
        ItemId: itemId,
        Page: page,
        PageSize: pageSize
    };

    return yield call(callServer, "/WebAPI/Feature/Order/GetOrders", "POST", orderRequestData);
}

function* submitEkey(action) {
    try {
        yield delay(500);
        const res = yield call(
            callServer,
            "/WebAPI/Feature/License/ActivateDigitalProduct",
            "POST",
            action.ekey
        );

        if (res.Payload) {
            yield put(ordersActions.ekeySuccess());
        } else {
            yield put(ordersActions.ekeyError());
        }
    } catch (error) {
        yield put(errorActions.addError(error.message));
    } finally {
        yield put(ordersActions.ekeyComplete());
    }
}

export default function* orderSaga() {
    yield all([
        fork(function* () {
            yield takeEvery("ORDERS_INIT", initFetchOrders);
        }),
        fork(function* () {
            yield takeEvery("ORDERS_LOAD_MORE", ordersLoadMore);
        }),
        fork(function* () {
            yield takeEvery("ORDERS_EKEY_SUBMIT", submitEkey);
        })
    ]);
}
