import { useCallback, useEffect, useState } from "react";

export interface IUseAsyncOptions<T = unknown> {
    immediate?: boolean;
    onSuccess?: (data: T) => void;
    onError?: () => void;
    onExecute?: () => void;
    keepPreviousData?: boolean;
}

const useAsync = <T, E = string>(
    asyncFunction: (data?: any) => Promise<T>,
    { immediate = true, onSuccess, onError, onExecute, keepPreviousData = false }: IUseAsyncOptions<T>
) => {
    const [status, setStatus] = useState<"idle" | "pending" | "success" | "error">("idle");
    const [value, setValue] = useState<T | null>(null);
    const [error, setError] = useState<E | null>(null);

    const isPending = status === "pending";
    const isSuccess = status === "success";
    const isError = status === "error";
    const isIdle = status === "idle";

    // Reset ALL state
    const reset = useCallback(() => {
        setStatus("idle");
        setValue(null);
        setError(null);
    }, []);

    // The execute function wraps asyncFunction and
    // handles setting state for pending, value, and error.
    // useCallback ensures the below useEffect is not called
    // on every render, but only if asyncFunction changes.
    const execute = useCallback(() => {
        if (isPending) {
            return;
        }
        onExecute?.();
        setStatus("pending");
        if (!keepPreviousData) {
            setValue(null);
        }
        setError(null);
        return asyncFunction()
            .then(response => {
                setValue(response);
                setStatus("success");
                onSuccess?.(response);
            })
            .catch(error => {
                setError(error);
                setStatus("error");
                onError?.();
            });
    }, [asyncFunction]);

    // Call execute if we want to fire it right away.
    // Otherwise execute can be called later, such as
    // in an onClick handler.
    useEffect(() => {
        if (immediate) {
            execute();
        }
    }, [execute, immediate]);

    return {
        reset,
        execute,
        status,
        value,
        error,
        isPending,
        isSuccess,
        isError,
        isIdle
    };
};

export default useAsync;
