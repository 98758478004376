import { fork, put, takeEvery, delay, all } from "redux-saga/effects";
import { callServer } from "../../ReactApi/serverService";
import { contactFormActions } from "./contact-form-reducer";
import { errorActions } from "../Error/error-reducer";

function* contactFormSubmit(action) {
    try {
        yield delay(500);

        let data = action.formInfo;
        const url = action.webApiUrl;

        if (data.form && data.form == "dynamic") {
            const { formTitle, formHeading, captchaResponse, formId } = action.formInfo;

            const fields = Object.entries(data)
                .map(([key, value]) => ({ name: key, value: value }))
                .filter(
                    c =>
                        c.name !== "formTitle" &&
                        c !== "formHeading" &&
                        c.name !== "formId" &&
                        c.name !== "form"
                );

            data = {
                formId: formId,
                captchaResponse: captchaResponse,
                formHeading: formHeading,
                formTitle: formTitle,
                fields: fields
            };
        }

        const res = yield callServer(url, "POST", data);

        if (!res.Success) {
            if (!res.Payload) {
                yield put(errorActions.addError(res.ErrorMessage));
            } else {
                yield put(contactFormActions.contactFormSubmitError());
                action.resolve(res);
            }
        } else {
            yield put(contactFormActions.contactFormSubmitSuccess());
            action.resolve(res);
        }
    } catch (error) {
        yield put(errorActions.addError(error.message));
        action.reject();
    }
}

export default function* contactFormSaga() {
    yield all([
        fork(function* () {
            yield takeEvery("CONTACT_FORM_SUBMIT", contactFormSubmit);
        })
    ]);
}
