import { fork, select, put, call, takeEvery, all } from "redux-saga/effects";
import { callServer } from "../../ReactApi/serverService";
import {
    articleTeaserListSelector,
    articleTeaserListActions as actions
} from "./article-teaser-list-reducer";

function* filterChanged() {
    const res = yield fetchArticles();

    if (res.Success) {
        yield put(actions.articlesLoadSuccess(res.Payload));
    } else {
        yield put(actions.articlesLoadError(res.ErrorMessage, 3500));
    }
}

function* articlesLoadMore() {
    const res = yield fetchArticles();

    if (res.Success) {
        yield put(actions.articlesLoadMoreSuccess(res.Payload));
    } else {
        yield put(actions.articlesLoadError(res.ErrorMessage, 3500));
    }
}

function* fetchArticles() {
    const filters = yield select(articleTeaserListSelector.filters);
    const page = yield select(articleTeaserListSelector.page);
    const pageSize = yield select(articleTeaserListSelector.pageSize);

    return yield call(callServer, "/WebAPI/Feature/ContentElements/FilterArticleList", "POST", {
        SelectedFilters: filters
            .filter(value => {
                return value.Enabled;
            })
            .map(value => {
                return value.ItemId;
            }),
        Page: page,
        PageSize: pageSize
    });
}

export default function* articleTeaserListSaga() {
    yield all([
        fork(function* () {
            yield takeEvery("FILTER_CHANGED", filterChanged);
        }),
        fork(function* () {
            yield takeEvery("FILTER_RESET", filterChanged);
        }),
        fork(function* () {
            yield takeEvery("ARTICLES_LOAD_MORE", articlesLoadMore);
        })
    ]);
}
