import { fromJS } from "immutable";

export const newsletterActions = {
    newsletterSignup: (data, resolve, reject, apiUrl) => {
        return { type: "NEWSLETTER_SIGNUP", data, resolve, reject, apiUrl };
    },
    newsletterSignupSuccess: () => {
        return { type: "NEWSLETTER_SIGNUP_SUCCESS" };
    },
    newsletterSignupError: () => {
        return { type: "NEWSLETTER_SIGNUP_ERROR" };
    },
    newsletterSignupComplete: () => {
        return { type: "NEWSLETTER_SIGNUP_COMPLETE " };
    },
    newsletterSettingsSave: (data, resolve, reject, apiUrl) => {
        return { type: "NEWSLETTER_SETTINGS_SAVE", data, resolve, reject, apiUrl };
    },
    newsletterSettingsSaveSuccess: () => {
        return { type: "NEWSLETTER_SETTINGS_SAVE_SUCCESS" };
    },
    newsletterSettingsSaveError: () => {
        return { type: "NEWSLETTER_SETTINGS_SAVE_ERROR" };
    },
    newsletterSettingsSaveComplete: () => {
        return { type: "NEWSLETTER_SETTINGS_SAVE_COMPLETE" };
    }
};

const init = {
    newsletterSignupPending: false,
    newsletterSettingsSavePending: false
};

//Reducer
const newsletterReducer = function (state = fromJS(init), action) {
    switch (action.type) {
        case "NEWSLETTER_SIGNUP":
            return state.set("newsletterSignupPending", true);

        case "NEWSLETTER_SIGNUP_ERROR":
            return state.set("newsletterSignupPending", false);

        case "NEWSLETTER_SIGNUP_COMPLETE":
            return state.set("newsletterSignupPending", false);

        case "NEWSLETTER_SETTINGS_SAVE":
            return state.set("newsletterSettingsSavePending", true);

        case "NEWSLETTER_SETTINGS_SAVE_ERROR":
            return state.set("newsletterSettingsSavePending", false);

        case "NEWSLETTER_SETTINGS_SAVE_COMPLETE":
            return state.set("newsletterSettingsSavePending", false);

        default:
            return state;
    }
};

export const newsletterSelector = {
    newsletterSignupPending: state => state.newsletter.get("newsletterSignupPending"),
    newsletterSettingsSavePending: state => state.newsletter.get("newsletterSettingsSavePending")
};

export default newsletterReducer;
