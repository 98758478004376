import { Component } from "react";

class AppReduxInit extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return null;
    }
}

export default AppReduxInit;
