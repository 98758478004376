import React, { Component } from "react";

class IconCart extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M3 8L5.85245 8.88L9.68652 22.7491H26.0287"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                />
                <path
                    d="M9.1875 17.75H27.0153L28.9962 12.25"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                />
                <ellipse
                    cx="22.3129"
                    cy="25"
                    rx="1.98087"
                    ry="2"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                />
                <ellipse
                    cx="13.3988"
                    cy="25"
                    rx="1.98087"
                    ry="2"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                />
            </svg>
        );
    }
}

export default IconCart;
