import { defineView } from "@/ReactSetup/view-definitions";
// import { ordersActions } from "ReactReducers/Orders/orders-reducer";

const definition = defineView({
    name: "MyOrders",
    component: () => (__SERVER__ ? require("./profile-my-orders-view") : import("./profile-my-orders-view"))
    // setup: payload => {
    //     return ordersActions.init(payload);
    // }
});

export default definition;
