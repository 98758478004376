import { fromJS } from "immutable";

export const teacherSampleFormActions = {
    teacherSampleFormSubmit: (webApiUrl, formInfo, resolve, reject) => {
        return { type: "TEACHER_SAMPLE_FORM_SUBMIT", webApiUrl, formInfo, resolve, reject };
    },
    teacherSampleFormSubmitComplete: formInfo => {
        return { type: "TEACHER_SAMPLE_FORM_COMPLETE", formInfo };
    },
    teacherSampleFormSubmitError: formInfo => {
        return { type: "TEACHER_SAMPLE_FORM_ERROR", formInfo };
    },
    teacherSampleFormSubmitSuccess: formInfo => {
        return { type: "TEACHER_SAMPLE_FORM_SUCCESS", formInfo };
    }
};

const init = {
    teacherSampleFormSubmitPending: false,
    teacherSampleFormSubmitSuccess: false
};

//Reducer
const teacherSampleFormReducer = function (state = fromJS(init), action) {
    switch (action.type) {
        case "TEACHER_SAMPLE_FORM_SUBMIT":
            return state.set("teacherSampleFormSubmitPending", true);

        case "TEACHER_SAMPLE_FORM_COMPLETE":
            return state.set("teacherSampleFormSubmitPending", false);

        case "TEACHER_SAMPLE_FORM_SUCCESS":
            return state
                .set("teacherSampleFormSubmitPending", false)
                .set("teacherSampleFormSubmitSuccess", true);

        default:
            return state;
    }
};

export const teacherSampleFormSelector = {
    teacherSampleFormSubmitPending: state => state.teacherSampleForm.get("teacherSampleFormSubmitPending"),
    teacherSampleFormSubmitSuccess: state => state.teacherSampleForm.get("teacherSampleFormSubmitSuccess")
};

export default teacherSampleFormReducer;
