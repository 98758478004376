import { defineView } from "@/ReactSetup/view-definitions";

const definition = defineView({
    name: "ResendVerificationEmailButton",
    component: () =>
        __SERVER__
            ? require("./resend-verification-email-button-view")
            : import("./resend-verification-email-button-view")
});

export default definition;
