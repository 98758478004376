/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
// @ts-check
import classNames from "classnames";
import React, { Component } from "react";
import Portal from "ReactComponents/Portal/portal";
import FocusTrap from "ReactComponents/Trap/focus-trap";

/**
 * @param isOpen (boolean) visible or not
 * @param toClose (function) function that closes the model by setting isOpen to false
 * @param notCloseable (boolean)
 * @param showCloseButton (boolean)
 * @param modalClassName (string) class name for the modal element itself
 * @param labelledBy (string) used for aria-labelledby for accessability
 * @param size (string) determines the width of the modal (can be "normal", "big" or "auto")
 * @param removePadding (boolean) if set to true removes the padding on the modal content
 *
 */
class Modal extends Component {
    /**
     * @param {any} props
     */
    constructor(props) {
        super(props);
        this.state = {
            isClosing: false
        };
        this.closeModalHandler = this.closeModalHandler.bind(this);
        this.openModalHandler = this.openModalHandler.bind(this);
        this.onOverlayClick = this.onOverlayClick.bind(this);
        this.stopPropagation = this.stopPropagation.bind(this);
        this.onCloseHandler = this.onCloseHandler.bind(this);
        this.getPrivousActiveElement = this.getPrivousActiveElement.bind(this);
        this.previousActiveElement;
    }

    /**
     * @param {{ stopPropagation: () => void; }} e
     */
    stopPropagation(e) {
        e.stopPropagation();
    }

    openModalHandler() {}

    closeModalHandler() {
        if (this.props.notCloseable) {
            return;
        }

        if (this.previousActiveElement) {
            this.previousActiveElement.focus();
        }

        this.setState({ isClosing: true }); // to set animation class before removing from the DOM
        setTimeout(() => {
            if (this.props.toClose) {
                this.props.toClose();
            }
            this.setState({ isClosing: false });
        }, 250);
    }

    onCloseHandler() {
        if (this.props.notCloseable) {
            return;
        }
    }

    getPrivousActiveElement(el) {
        this.previousActiveElement = el;
    }

    onOverlayClick() {
        if (this.props.toClose) this.closeModalHandler();
    }

    render() {
        const esc = this.props.notCloseable ? false : true;
        const size = this.props.size || "normal";

        return (
            <Portal
                closeOnEsc={esc}
                closeOnOutsideClick
                isOpen={this.props.isOpen}
                onOpen={this.openModalHandler}
                onClose={this.closeModalHandler}
            >
                <div
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby={this.props.labelledBy}
                    aria-hidden={!this.props.isOpen}
                    className={classNames(
                        this.props.className,
                        {
                            isClosing: this.state.isClosing,
                            isOpen: this.props.isOpen,
                            "modal--no-padding": this.props.removePadding
                        },
                        `modal-size--${size}`,
                        "modal-overlay"
                    )}
                >
                    <FocusTrap
                        setPreviousActiveElement={this.getPrivousActiveElement}
                        active={this.props.isOpen}
                        className="modal-container-wrap"
                    >
                        <div className={classNames("modal-container", this.props.modalClassName)}>
                            {this.props.showCloseButton && (
                                <button
                                    type="button"
                                    className="modal-close-button button-close-block button--top-right"
                                    onClick={this.closeModalHandler}
                                >
                                    Luk dialog
                                </button>
                            )}
                            <div className="modal-container__content" role="document">
                                {this.props.children}
                            </div>
                        </div>
                    </FocusTrap>
                </div>
            </Portal>
        );
    }
}

export default Modal;
