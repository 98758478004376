import React from "react";
import { Transition } from "react-transition-group";
import classNames from "classnames";

const classes = {
    entering: "fade-enter",
    entered: "fade-enter-active",
    exiting: "fade-exit",
    exited: "fade-exit-active"
};

export function FadeTransition({ children, className, ...props }) {
    return (
        <Transition timeout={500} appear in={props.in}>
            {(state, innerProps) => {
                return React.cloneElement(children, {
                    ...innerProps,
                    className: classNames(className, children.props.className, "fade", classes[state])
                });
            }}
        </Transition>
    );
}
