import { fromJS } from "immutable";

export const searchActions = {
    initSearch: propsObj => {
        return { type: "INIT_SEARCH", propsObj };
    },
    queryChanged: query => {
        return { type: "QUERY_CHANGED", query };
    },
    setSubmittedQuery: () => {
        return { type: "SEARCH_QUERY_SET" };
    },
    searchSubmit: propsObj => {
        return { type: "SEARCH_SUBMIT", propsObj };
    },
    searchComplete: () => {
        return { type: "SEARCH_COMPLETE" };
    },

    contentSearchSuccess: results => {
        return { type: "CONTENT_SEARCH_SUCCESS", results };
    },
    contentSearchError: error => {
        return { type: "CONTENT_SEARCH_ERROR", error };
    },
    contentSearchNext: () => {
        return { type: "CONTENT_SEARCH_NEXT" };
    },
    contentSearchPrevious: () => {
        return { type: "CONTENT_SEARCH_PREVIOUS" };
    },
    contentSearchSubmit: () => {
        return { type: "CONTENT_SEARCH_SUBMIT" };
    },

    productSearchSuccess: results => {
        return { type: "PRODUCT_SEARCH_SUCCESS", results };
    },
    productSearchError: error => {
        return { type: "PRODUCT_SEARCH_ERROR", error };
    },
    productSearchNext: () => {
        return { type: "PRODUCT_SEARCH_NEXT" };
    },
    productSearchPrevious: () => {
        return { type: "PRODUCT_SEARCH_PREVIOUS" };
    },
    productSearchSubmit: () => {
        return { type: "PRODUCT_SEARCH_SUBMIT" };
    }
};

export const searchSelector = {
    query: state => state.search.get("query"),
    lastSubmittedQuery: state => state.search.get("lastSubmittedQuery"),
    pending: state => state.search.get("pending"),

    contentSearchResults: state => state.search.get("contentSearchResults"),
    contentSearchPage: state => state.search.get("contentSearchPage"),
    contentSearchPageSize: state => state.search.get("contentSearchPageSize"),
    contentSearchHasPrevious: state => state.search.get("contentSearchHasPrevious"),
    contentSearchHasNext: state => state.search.get("contentSearchHasNext"),
    contentSearchTotalCount: state => state.search.get("contentSearchTotalCount"),

    productSearchResults: state => state.search.get("productSearchResults"),
    productSearchPage: state => state.search.get("productSearchPage"),
    productSearchHasPrevious: state => state.search.get("productSearchHasPrevious"),
    productSearchHasNext: state => state.search.get("productSearchHasNext"),
    productSearchTotalCount: state => state.search.get("productSearchTotalCount")
};

function getUrlParameter(name) {
    if (!global.canUseDOM) return "";
    name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    var results = regex.exec(window.location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

const init = {
    query: "",
    pending: false,
    lastSubmittedQuery: "",

    contentSearchResults: [],
    contentSearchPage: 0,
    contentSearchPageSize: 10,
    contentSearchHasPrevious: false,
    contentSearchHasNext: false,
    contentSearchTotalCount: 0,

    productSearchResults: [],
    productSearchPage: 0,
    productSearchHasPrevious: false,
    productSearchHasNext: false,
    productSearchTotalCount: 0
};

//Reducer
const searchReducer = function (state = fromJS(init), action) {
    let page;
    switch (action.type) {
        case "INIT_SEARCH":
            return state
                .set("query", getUrlParameter("q") || "")
                .set(
                    "contentSearchPageSize",
                    action.propsObj.ContentPageSize || state.get("contentSearchPageSize")
                );

        case "QUERY_CHANGED":
            return state.set("query", action.query);

        case "SEARCH_QUERY_SET":
            return state.set("lastSubmittedQuery", state.get("query"));

        case "SEARCH_COMPLETE":
            return state.set("pending", false);

        case "CONTENT_SEARCH_SUBMIT":
            return state.set("contentSearchPage", 0).set("pending", true);

        case "CONTENT_SEARCH_SUCCESS":
            return state
                .set("contentSearchResults", action.results.Items)
                .set("contentSearchHasPrevious", action.results.HasPrevious)
                .set("contentSearchHasNext", action.results.HasNext)
                .set("contentSearchTotalCount", action.results.TotalCount);

        case "CONTENT_SEARCH_NEXT":
            page = state.get("contentSearchPage") + 1;
            return state.set("contentSearchPage", page);

        case "CONTENT_SEARCH_PREVIOUS":
            page = state.get("contentSearchPage") - 1;
            if (page < 0) page = 0;
            return state.set("contentSearchPage", page);

        case "PRODUCT_SEARCH_SUBMIT":
            return state.set("productSearchPage", 0);

        case "PRODUCT_SEARCH_SUCCESS":
            return state
                .set("productSearchResults", action.results.Items)
                .set("productSearchHasPrevious", action.results.HasPrevious)
                .set("productSearchHasNext", action.results.HasNext)
                .set("productSearchTotalCount", action.results.TotalCount);

        case "PRODUCT_SEARCH_NEXT":
            page = state.get("productSearchPage") + 1;
            return state.set("productSearchPage", page);

        case "PRODUCT_SEARCH_PREVIOUS":
            page = state.get("productSearchPage") - 1;
            if (page < 0) page = 0;
            return state.set("productSearchPage", page);

        default:
            return state;
    }
};

export default searchReducer;
