import * as React from "react";
import { ViewProvider } from "@/ReactSetup/view-provider";
import store from "../configureStore";

export default function createView(componentDefinition) {
    return class extends React.Component {
        componentDidMount() {
            if (componentDefinition.setup) {
                if (typeof componentDefinition.setup === "object") {
                    for (let f = 0; f < componentDefinition.setup.length; f++) {
                        store.dispatch(componentDefinition.setup[f](this.props));
                    }
                } else {
                    store.dispatch(componentDefinition.setup(this.props));
                }
            }
        }

        render() {
            const ViewComponent = componentDefinition.component;

            return (
                <ViewProvider>
                    <ViewComponent {...this.props} />
                </ViewProvider>
            );
        }
    };
}
