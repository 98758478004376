import { defineView } from "@/ReactSetup/view-definitions";
import { searchActions } from "ReactReducers/Search/search-reducer";
import { productListActions } from "ReactReducers/ProductList/product-list-reducer";

const definition = defineView({
    name: "SiteSearch",
    component: () => (__SERVER__ ? require("./site-search-view") : import("./site-search-view")),
    setup: [
        payload => {
            return searchActions.initSearch(payload);
        },
        payload => {
            return productListActions.setup({ Payload: payload.ProductListData });
        }
    ]
});

export default definition;
