import React from "react";
import { useDispatch } from "react-redux";
import { useSendVerificationEmail } from "ReactApi/methods/sendVerificationEmail";
import FormSubmitError from "ReactComponents/FormSubmitError/form-submit-error";
import Modal from "ReactComponents/Modal/modal";
import SubmitButton from "ReactComponents/Shared/FormInputs/spinner-button";
import { profileActions } from "ReactReducers/Profile/profile-reducer";

export interface IAccountVerificationMissingModalProps {
    labels: {
        EmailNotVerifiedHeader: string;
        EmailNotVerifiedBodyText: string;
        SendLinkButtonLabel: string;
        LinkSentButtonLabel: string;
    };
}

const AccountVerificationMissingModal: React.FC<IAccountVerificationMissingModalProps> = ({
    labels: { EmailNotVerifiedHeader, EmailNotVerifiedBodyText, SendLinkButtonLabel, LinkSentButtonLabel }
}) => {
    const dispatch = useDispatch();
    const sendVerificationMail = useSendVerificationEmail({
        onSuccess() {
            setTimeout(() => dispatch(profileActions.userLogout()), 5000);
        }
    });

    return (
        <Modal
            isOpen
            toClose={() => dispatch(profileActions.userLogout())}
            modalClassName="account-verification-missing-modal"
            showCloseButton
            labelledBy="email-not-verified-header"
        >
            <div className="account-verification-missing-modal__content">
                <h4 id="email-not-verified-header">{EmailNotVerifiedHeader}</h4>
                <p>{EmailNotVerifiedBodyText}</p>
                <SubmitButton
                    disabled={sendVerificationMail.isPending || sendVerificationMail.isSuccess}
                    type="button"
                    onClick={sendVerificationMail.execute}
                    className="button button-primary"
                    label={sendVerificationMail.isSuccess ? LinkSentButtonLabel : SendLinkButtonLabel}
                    isLoading={sendVerificationMail.isPending}
                />
                {sendVerificationMail.error?.ErrorMessage && (
                    <FormSubmitError error={sendVerificationMail.error.ErrorMessage} />
                )}
            </div>
        </Modal>
    );
};

export default AccountVerificationMissingModal;
