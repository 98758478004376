import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILogin, IProfile } from "ReactComponents/Navigation/navigation-types";
import { RootState } from "types";

type EmptyObject = Record<string, never>;

type Link = {
    Url: string;
    Text: string;
};

export interface IProfileState {
    userInfo: IProfile | EmptyObject | undefined;
    createLoginContent: any | EmptyObject | undefined;
    uniPromptContent: any | EmptyObject | undefined;
    isUniLoggedInOnly: boolean;
    userCreatePending: boolean;
    createProfileUrl: Link | undefined;
    createProfileUniLoginUrl: Link | undefined;
    loggedIn: boolean | undefined;
    loginRedirecting: boolean | undefined;
    userName: string | undefined;
    profileLinks: Array<{ Url: string; Text: string }>;
    loginUrl: string | undefined;
    logoutUrl: string | undefined;
    login: ILogin | undefined;
    verificationModal:
        | {
              EmailNotVerifiedHeader: string;
              EmailNotVerifiedBodyText: string;
              SendLinkButtonLabel: string;
              LinkSentButtonLabel: string;
          }
        | undefined;
}

const initialState: IProfileState = {
    userInfo: {},
    createLoginContent: {},
    uniPromptContent: {},
    login: undefined,
    isUniLoggedInOnly: false,
    userCreatePending: false,
    createProfileUrl: undefined,
    createProfileUniLoginUrl: undefined,
    loggedIn: undefined,
    loginRedirecting: undefined,
    userName: undefined,
    profileLinks: [],
    loginUrl: undefined,
    logoutUrl: undefined,
    verificationModal: undefined
};

const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {
        initLogin: (
            state,
            action: PayloadAction<{
                UserProfile?: IProfile;
                Login?: ILogin;
                AccountVerificationModalLabels?: IProfileState["verificationModal"];
            }>
        ) => {
            console.log("AccountVerificationModalLabels:", action.payload.AccountVerificationModalLabels);
            state.userInfo = action.payload.UserProfile;
            state.createLoginContent = action.payload.Login?.CreateLoginContent;
            state.uniPromptContent = action.payload.Login?.UniPromptContent;
            state.createProfileUrl = action.payload.Login?.CreateProfileUrl;
            state.createProfileUniLoginUrl = action.payload.Login?.CreateProfileUniLoginUrl;
            state.isUniLoggedInOnly = !!action.payload.Login?.IsUniLoggedIn && !action.payload.UserProfile;
            state.login = action.payload.Login;
            state.verificationModal = action.payload.AccountVerificationModalLabels;
        },
        userLogin: state => state,
        userLoginSuccess: state => {
            state.loggedIn = true;
        },
        userLoginError: state => {
            state.loggedIn = false;
        },
        userLoginComplete: state => {
            if (window) {
                window.location.reload();
            }
            state.loginRedirecting = true;
        },
        userLoggedIn: state => state,
        userLogout: state => state,
        userCreateSubmit: (
            state,
            _action: PayloadAction<{ userinfo: IProfile; apiUrl: any; resolve: any; reject: any }>
        ) => {
            state.userCreatePending = true;
        },
        userCreateComplete: state => {
            state.userCreatePending = false;
        },
        unicConnect: state => state,
        getNewsletterProfile: (
            state,
            _action: PayloadAction<{ email: string; apiUrl: any; resolve: any; reject: any }>
        ) => state
    }
});

export const profileActions = profileSlice.actions;

export const profileSelector = {
    userInfo: (state: RootState) => state.profile.userInfo,
    loggedIn: (state: RootState) => {
        return Boolean(state.profile.userInfo?.Email);
    },
    loginUrl: (state: RootState) => state.profile.loginUrl,
    logoutUrl: (state: RootState) => state.profile.logoutUrl,
    profileLinks: (state: RootState) => state.profile.profileLinks,
    userName: (state: RootState) => state.profile.userName,
    createProfileUrl: (state: RootState) => state.profile.createProfileUrl,
    createProfileUniLoginUrl: (state: RootState) => state.profile.createProfileUniLoginUrl,
    userCreatePending: (state: RootState) => state.profile.userCreatePending,
    loginRedirecting: (state: RootState) => state.profile.loginRedirecting,
    createLoginContent: (state: RootState) => state.profile.createLoginContent,
    uniPromptContent: (state: RootState) => state.profile.uniPromptContent,
    isUniLoggedInOnly: (state: RootState) => state.profile.isUniLoggedInOnly,
    login: (state: RootState) => state.profile.login,
    verificationModal: (state: RootState) => state.profile.verificationModal
};

export default profileSlice.reducer;
