const VIEW_SETUP_QUERY_SELECTOR = "[data-react-setup]";
const VIEW_DOM_SETUP_NAME_PREFIX = "ReactComponents.";

// function createCoreMountViews(rootNode) {
//     return CORE_VIEWS.reduce((mountViews, name) => {
//         const node = global.window.document.createElement("div");
//         rootNode.appendChild(node);
//         return [
//             ...mountViews,
//             {
//                 name,
//                 node
//             }
//         ];
//     }, []);
// }

function getDomViews(domSetups) {
    return domSetups.reduce((mountViews, setup) => {
        const parameters = parseDomViewSetup(setup);
        if (!parameters) {
            console.error("View Finder: Parse error for", setup);
            return mountViews;
        }

        const name = parameters.component.replace(VIEW_DOM_SETUP_NAME_PREFIX, "");
        const node = document.getElementById(parameters.domId);

        if (!node) {
            console.error(`View Finder: DOM element with id ${parameters.domId} was not found.`);
            return mountViews;
        }

        return [
            ...mountViews,
            {
                name,
                node,
                props: parameters.props
            }
        ];
    }, []);
}

function parseDomViewSetup(setup) {
    let parsed;

    try {
        parsed = JSON.parse(setup.innerHTML);
    } catch (e) {
        return;
    }

    return parsed;
}

export default function viewFinder(rootNode = global.window.document.body) {
    // const coreViews = createCoreMountViews(rootNode);
    return getDomViews(Array.from(rootNode.querySelectorAll(VIEW_SETUP_QUERY_SELECTOR)));
    // return [...coreViews, ...domViews];
}
