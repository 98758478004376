import React, { Component } from "react";

/**
 * @param {boolean} isLoading - true or false when loading
 * @param {function} onClick - function when clicked
 * @param {string} label - text show in button
 * @param {string} type - the button type
 * @param {string} context - the context of the button, text is visually hidden
 *
 */
class SubmitButton extends Component {
    render() {
        return (
            <button
                className={this.props.className}
                onClick={() => !this.props.isLoading && this.props.onClick?.()}
                type={this.props.type}
                disabled={this.props.disabled || this.props.isLoading}
                aria-busy={this.props.isLoading}
            >
                {!this.props.isLoading ? (
                    <>
                        <span dangerouslySetInnerHTML={{ __html: this.props.label }} />
                        <span className="sr-only">{this.props.context}</span>
                    </>
                ) : (
                    <span className="loader">
                        <span />
                    </span>
                )}
            </button>
        );
    }
}

export default SubmitButton;
