import { useAppSelector } from "hooks/store-hooks";
import { basketSelector } from "./basket-reducer";

export function useBasket() {
    return useAppSelector(function (state) {
        return {
            isAdding: basketSelector.isAdding(state),
            isRemoving: basketSelector.isRemoving(state),
            isSettingQuatity: basketSelector.isSettingQuatity(state),
            isSettingClasses: basketSelector.isSettingClasses(state),
            basket: basketSelector.basket(state),
            discountCodePending: basketSelector.discountCodePending(state),
            discountCodeSuccess: basketSelector.discountCodeSuccess(state),
            discountCodeError: basketSelector.discountCodeError(state),
            discountCodeComplete: basketSelector.discountCodeComplete(state)
        };
    });
}
