import * as React from "react";
import { FadeTransition } from "../Shared/AnimationComponents/fade-transition";
import { useAppDispatch, useAppSelector } from "hooks/store-hooks";
import classNames from "classnames";
import { overlayActions, overlaySelector } from "ReactReducers/Overlay/overlay-reducer";

function Overlay() {
    const visible = useAppSelector(overlaySelector.visible);

    React.useEffect(() => {
        if (visible) {
            document.documentElement.classList.add("nav-locked-scroll");
        } else {
            document.documentElement.classList.remove("nav-locked-scroll");
        }
    }, [visible]);

    return (
        <FadeTransition in={visible}>
            <OverlayInner />
        </FadeTransition>
    );
}

function OverlayInner(props: JSX.IntrinsicElements["div"]) {
    const dispatch = useAppDispatch();
    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
        <div
            {...props}
            onClick={() => dispatch(overlayActions.clicked())}
            className={classNames("overlay", props.className)}
        />
    );
}

export default Overlay;
