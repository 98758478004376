//---------------Document location params

export const urlParameters = {
    params: {},
    pathname: null,

    updateParameters: function updateParameters() {
        if (typeof document == "undefined") {
            return;
        } //serverside check

        this.pathname = document.location.pathname;
        if (document.location.toString().indexOf("?") !== -1) {
            var query = document.location
                .toString()
                .replace(/^.*?\?/, "")
                .replace(/#.*$/, "")
                .split("&");

            for (var i = 0, l = query.length; i < l; i++) {
                var aux = decodeURIComponent(query[i]).split("=");
                this.params[aux[0]] = aux[1];
            }
        }
        return this;
    },

    getParameterByName: function getParameterByName(name) {
        this.updateParameters();
        return this.params[name];
    },

    getAllParameters: function getAllParameters() {
        this.updateParameters();
        return this.params;
    },

    setParameter: function setParameter(key, value) {
        this.updateParameters();
        if (value === "" || value === null || value === undefined) {
            delete this.params[key];
        } else {
            this.params[key] = value;
        }
        return this;
    },

    clearAllParams: function clearAllParams() {
        this.params = {};
    },

    updateURL: function updateURL() {
        var str = "";
        for (var key in this.params) {
            if (str !== "") {
                str += "&";
            }
            str += key + "=" + encodeURIComponent(this.params[key]);
        }
        window.history.pushState({}, "", this.pathname + (str != "" ? "?" : "") + str);
        this.updateParameters();
        return this;
    },

    setParamsAsString: function setParamsAsString(string) {
        var mark = "?";
        if (string.indexOf("?") != -1) {
            mark = "";
        }
        window.history.replaceState({}, "", this.pathname + mark + string);
    },

    replaceURL: function replaceURL() {
        var str = "";
        for (var key in this.params) {
            if (str != "") {
                str += "&";
            }
            str += key + "=" + encodeURIComponent(this.params[key]);
        }
        window.history.replaceState({}, "", this.pathname + (str != "" ? "?" : "") + str);
        this.updateParameters();
        return this;
    },

    setHash: function setHash(value) {
        if (history.replaceState) {
            history.replaceState(null, null, "#" + value);
        } else {
            location.hash = "#" + value;
        }
    },

    getHash: function getHash() {
        if (window.location.hash) {
            return window.location.hash.replace("#", "");
        }
    }
};
