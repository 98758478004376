export const classesSelectedArray = ConfigClassesArrayOfObj => {
    return ConfigClassesArrayOfObj.filter(obj => obj.Selected).map(obj => obj.Label);
};

export const sameClassesSelected = (basketClassesArray, ConfigClassesArrayOfObj) => {
    const configSelectedLabelsOnly = classesSelectedArray(ConfigClassesArrayOfObj);
    if (!basketClassesArray || basketClassesArray.length !== configSelectedLabelsOnly.length) {
        return false;
    }
    for (let i = 0; i <= basketClassesArray.length; i++) {
        if (configSelectedLabelsOnly[i] !== basketClassesArray[i]) {
            return false;
        }
    }
    return basketClassesArray;
};
