import * as React from "react";
import * as ReactDOM from "react-dom";
import { findViewDefinition, isAsyncComponent } from "@/ReactSetup/view-definitions";
import createView from "@/ReactSetup/view-initializer";

export default async function viewMounter({ name, node, props = {} }) {
    const viewDefinition = findViewDefinition(name);

    if (!viewDefinition) {
        // tslint:disable-next-line:no-console
        console.log("Cannot find a component definition with the name:", name);
        return;
    }

    let View;

    const component = viewDefinition.component;

    if (isAsyncComponent(component)) {
        try {
            View = await viewDefinition.component();
            View = createView({ ...viewDefinition, component: View.default });
        } catch (e) {
            console.log("Could not load component with name:", name, e);
            return;
        }
    } else {
        View = createView({ ...viewDefinition, component: component().default });
    }

    View.displayName = viewDefinition.name;

    const renderOrHydrate = node.innerHTML.trim().length ? "hydrate" : "render";
    ReactDOM[renderOrHydrate](<View {...props} />, node);
}
