import { fork, put, call, takeEvery, all } from "redux-saga/effects";
import { callServer } from "ReactApi/serverService";
import { trialLicenseActions } from "./trial-license-reducer";
import { errorActions } from "ReactReducers/Error/error-reducer";
import { orderTrialLicenseTracking } from "ReactComponents/Shared/Utils/tracking";

function* trialLicenseRequest(action) {
    try {
        yield put(trialLicenseActions.trialLicensePending());

        const data = { Isbn: action.Payload.Isbn, PhysicalProductUrl: action.Payload.PhysicalProductUrl };

        const response = yield call(callServer, "/WebAPI/Feature/License/CreateTrialLicense", "POST", data);

        if (response.Success) {
            yield call(orderTrialLicenseTracking);
            yield put(trialLicenseActions.trialLicenseRequestSuccess());
            yield put(trialLicenseActions.trialLicenseUpdate(response.Payload));
        } else {
            yield put(trialLicenseActions.trialLicenseError());
            yield put(trialLicenseActions.trialLicenseUpdate(response.Payload));
            yield put(errorActions.addError(response.ErrorMessage));
        }
    } catch (error) {
        yield put(errorActions.addError(error.message));
    } finally {
        yield put(trialLicenseActions.trialLicenseComplete());
    }
}

function* trialLicenseValidate(action) {
    try {
        yield put(trialLicenseActions.trialLicensePending());

        const data = { isbn: action.Payload.isbn };
        const response = yield call(callServer, "/WebAPI/Feature/License/ValidateTrialLicense", "POST", data);

        if (!response.ErrorMessage) {
            yield put(trialLicenseActions.trialLicenseValidateSuccess());
            yield put(trialLicenseActions.trialLicenseUpdate(response.Payload));
        } else {
            yield put(trialLicenseActions.trialLicenseError(response.ErrorMessage));
        }
    } catch (error) {
        yield put(errorActions.addError(error.message));
    } finally {
        yield put(trialLicenseActions.trialLicenseComplete());
    }
}

export default function* basketSaga() {
    yield all([
        fork(function* () {
            yield takeEvery("TRIAL_LICENSE_REQUEST", trialLicenseRequest);
        }),
        fork(function* () {
            yield takeEvery("TRIAL_LICENSE_VALIDATE", trialLicenseValidate);
        })
    ]);
}
