import { fork, put, call, takeLatest, delay, all } from "redux-saga/effects";
import { callServer } from "ReactApi/serverService";
import { updatePaymentActions } from "./update-payment-reducer";
import { errorActions } from "ReactReducers/Error/error-reducer";
import { redirectPost } from "../../js/utils/redirect";

function* updatePaymentRequest(action) {
    try {
        yield delay(500);
        const response = yield call(callServer, "/WebAPI/Feature/Payment/GetUpdatePaymentInfoModel", "POST");

        if (response.Success) {
            yield put(updatePaymentActions.updatePaymentSuccess());

            yield put(
                updatePaymentActions.updatePaymentSubmit({
                    provider: action.paymentProvider,
                    data: response.Payload
                })
            );
        } else {
            yield put(updatePaymentActions.updatePaymentError());
            yield put(errorActions.addError(response.ErrorMessage));
        }
    } catch (error) {
        yield put(updatePaymentActions.updatePaymentError());
        yield put(errorActions.addError(error.message));
    } finally {
        yield put(updatePaymentActions.updatePaymentComplete());
    }
}

function updatePaymentSubmit(action) {
    console.log(action);

    if (action.Payload.provider == "Reepay") {
        window.location.href = action.Payload.data.Url;
    } else redirectPost(action.Payload.data.Url, action.Payload.data.FormFields);
}

export default function* updatePaymentSaga() {
    yield all([
        fork(function* () {
            yield takeLatest("UPDATE_PAYMENT_REQUEST", updatePaymentRequest);
        }),
        fork(function* () {
            yield takeLatest("UPDATE_PAYMENT_SUBMIT", updatePaymentSubmit);
        })
    ]);
}
