import React, { Component } from "react";
import { connect } from "react-redux";
import { TransitionGroup } from "react-transition-group";
import { errorSelector, errorActions as actions } from "ReactReducers/Error/error-reducer";
import Error from "./error";

const mapStateToProps = function (state) {
    return {
        errors: errorSelector.errors(state)
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        addError: function () {
            dispatch(actions.addError());
        },
        clearError: function (index) {
            dispatch(actions.clearError(index));
        }
    };
};

class Errors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMounted: false //using to not rendring serverside
        };
    }

    componentDidMount() {
        this.setState({ isMounted: true });
    }

    render() {
        return (
            <div className="app-errors">
                {this.state.isMounted && (
                    <TransitionGroup>
                        {this.props.errors.map(error => (
                            <Error key={error.id} onClear={this.props.clearError} {...error} />
                        ))}
                    </TransitionGroup>
                )}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Errors);
