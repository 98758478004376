import { fork, put, call, takeLatest, all } from "redux-saga/effects";
import { callServer } from "../../ReactApi/serverService";
import { checkoutActions } from "./checkout-reducer";
import { errorActions } from "ReactReducers/Error/error-reducer";
import { redirectPost } from "../../js/utils/redirect";

function buildRequestModel(data) {
    return {
        //TODO
        BillingAddress: data.BillingAddress || {},
        /*{
            FirstName   : '',
            LastName    : '',
            CompanyName : '',
            Att         : '',
            Adresse     : '',
            PostalCode  : '',
            City        : '',
            Email       : '',
            MobileNumber: ''
        }*/
        ShippingAddress: data.ShippingAddress || {},
        /*{
            FirstName   : '',
            LastName    : '',
            CompanyName : '',
            Att         : '',
            Adresse     : '',
            PostalCode  : '',
            City        : '',
            Email       : '',
            MobileNumber: ''
        }*/
        UseShippingAddress: data.UseShippingAddress,
        DeliveryDate: data.DeliveryDate, // 'soonAsPossible '(default) || 'customDate'
        CustomDeliveryDate: data.CustomDeliveryDate, // dd/mm/yyyy
        NewsletterSignup: data.NewsletterSignup,
        PaymentType: data.PaymentType, // 'invoiceElectronic', 'invoicePDF' or 'creditcard'
        InvoiceEan: data.InvoiceEan,
        CustomerReference: data.CustomerReference,
        RequisitionNumber: data.RequisitionNumber,
        AcceptTerms: data.AcceptTerms,
        AcceptCancellationTerms: data.AcceptCancellationTerms,
        Email: data.Email,
        MobileNumber: data.MobileNumber,
        FirstName: data.FirstName,
        LastName: data.LastName,
        Messages: data.Messages
    };
}

function* submitUserInfo(action) {
    //action {type, data, promise:{r,r} }
    try {
        const resp = yield call(
            callServer,
            "/WebAPI/Feature/Checkout/SubmitUserInfo",
            "POST",
            buildRequestModel(action.data)
        );

        if (resp.Success) {
            action.promiseMethods.resolve(resp);
        } else {
            action.promiseMethods.reject(resp);
        }
    } catch (err) {
        action.promiseMethods.reject(err);
        yield put(errorActions.addError(err));
    } finally {
        yield put(checkoutActions.checkoutComplete());
    }
}

function* invoiceBegin(action) {
    try {
        const resp = yield call(
            callServer,
            "/WebAPI/Feature/Checkout/SubmitUserInfoInvoice",
            "POST",
            buildRequestModel(action.data)
        );

        if (resp.Success) {
            window.location = resp.Payload.SuccessUrl;
        } else {
            yield put(errorActions.addError(resp.Payload));
        }
    } catch (error) {
        console.log(error);
    } finally {
        yield put(checkoutActions.checkoutComplete());
    }
}

function* creditCardBegin(action) {
    try {
        const resp = yield call(callServer, action.url, "POST", buildRequestModel(action.data));

        if (resp.Success) {
            yield put(
                checkoutActions.creditCardSubmit({ payload: resp.Payload, provider: action.paymentProvider })
            );
        } else {
            yield put(errorActions.addError(resp.Payload));
        }
    } catch (error) {
        console.log(error);
    } finally {
        yield put(checkoutActions.checkoutComplete());
    }
}

function creditCardSubmit(action) {
    if (action.data.provider == "Reepay") {
        window.location.href = action.data.payload.Url;
    } else {
        redirectPost(action.data.payload.Url, action.data.payload.FormFields);
    }
}

export default function* checkoutSaga() {
    yield all([
        fork(function* () {
            yield takeLatest("CHECKOUT_INVOICE_BEGIN", invoiceBegin);
        }),
        fork(function* () {
            yield takeLatest("CHECKOUT_CREDITCARD_BEGIN", creditCardBegin);
        }),
        fork(function* () {
            yield takeLatest("CHECKOUT_CREDITCARD_SUBMIT", creditCardSubmit);
        }),
        fork(function* () {
            yield takeLatest("CHECKOUT_SUBMIT_USER_INFO", submitUserInfo);
        })
    ]);
}
