import { fork, put, takeEvery, delay, all } from "redux-saga/effects";
import { callServer } from "../../ReactApi/serverService";
import { teacherSampleFormActions } from "./teacher-sample-form-reducer";
import { errorActions } from "../Error/error-reducer";

function* teacherSampleFormSubmit(action) {
    try {
        yield delay(500);

        const data = action.formInfo;
        const url = action.webApiUrl;
        const res = yield callServer(url, "POST", data);

        if (!res.Success) {
            if (!res.Payload) {
                yield put(errorActions.addError(res.ErrorMessage));
            } else {
                yield put(teacherSampleFormActions.teacherSampleFormSubmitError());
                action.resolve(res);
            }
        } else {
            yield put(teacherSampleFormActions.teacherSampleFormSubmitSuccess());
            action.resolve(res);
        }
    } catch (error) {
        yield put(errorActions.addError(error.message));
        action.reject(error);
    } finally {
        yield put(teacherSampleFormActions.teacherSampleFormSubmitComplete());
    }
}

export default function* teacherSampleFormSaga() {
    yield all([
        fork(function* () {
            yield takeEvery("TEACHER_SAMPLE_FORM_SUBMIT", teacherSampleFormSubmit);
        })
    ]);
}
