import classNames from "classnames";
import { useAppSelector } from "hooks/store-hooks";
import * as React from "react";
import { InlineSearch } from "ReactComponents/InlineSearch/inline-search";
import { navigationSelector } from "ReactReducers/Navigation/navigation-reducer";

interface INavigationSearchProps {
    Placeholder: string;
}

export function NavigationSearch(props: INavigationSearchProps) {
    const isOpen = useAppSelector(navigationSelector.isSearchOpen);
    const ref = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        if (!isOpen) {
            return;
        }
        ref.current?.focus();
    }, [isOpen]);

    return (
        <div className={classNames("navigation-search", { "navigation-search--isOpen": isOpen })}>
            <InlineSearch {...props} ref={ref} open={isOpen} />
        </div>
    );
}
