// @ts-check
import { fork, put, call, takeEvery, takeLatest, all } from "redux-saga/effects";
import { callServer } from "../../ReactApi/serverService";
import { isAuthorized, isAuthorizedChanged, getAuthProvider } from "../../ReactApi/profileService";
import { profileActions } from "./profile-reducer";
import { errorActions } from "../Error/error-reducer";
import { docCookies } from "ReactApi/docCookies";
import { createProfileTracking } from "../../ReactComponents/Shared/Utils/tracking";

function getPopupWindowParams() {
    const width = 340;
    const height = 715;
    return (
        "status=no,height=" +
        height +
        ",width=" +
        width +
        ",resizable=yes,toolbar=no,menubar=no,scrollbars=no,directories=no"
    );
}

let loginUrl = null;
let logoutUrl = null;
let logoutFullUrl = null;

function initLogin(action) {
    if (!action.payload.Login) {
        return;
    }
    loginUrl = action.payload.Login.LoginUrl.FullUrl;
    logoutUrl = action.payload.Login.LogoutUrl.Url;
    logoutFullUrl = action.payload.Login.LogoutUrl.FullUrl;
}

function* userLoggedIn() {
    try {
        const authorized = yield isAuthorized(false);

        if (authorized) yield put(profileActions.userLoginSuccess());
        else yield put(profileActions.userLoginError());
    } catch (error) {
        yield put(errorActions.addError(error));
    }
}

function* userLogin() {
    if (!window) return; //serverside rendering
    if (loginUrl == null) {
        console.error("Login functionality not initialized!");
        return;
    }

    window.location = loginUrl;

    if (yield isAuthorized(false)) {
        const cookie = yield docCookies.getItem("authenticationToken");
        const authorizedChange = yield isAuthorizedChanged(cookie, true);
        if (authorizedChange) yield put(profileActions.userLoginSuccess());
        else yield put(profileActions.userLoginError());

        yield put(profileActions.userLoginComplete());
    } else {
        const authorized = yield isAuthorized(true);

        if (authorized) yield put(profileActions.userLoginSuccess());
        else yield put(profileActions.userLoginError());

        yield put(profileActions.userLoginComplete());
    }
}

function unicConnect() {
    if (!window) return; //serverside rendering
    if (loginUrl == null) {
        console.error("Login functionality not initialized!");
        return;
    }

    window.location = loginUrl + "&providerName=UNIC";
}

function* userLogout() {
    if (!window) return; //serverside rendering
    if (logoutUrl == null) {
        console.error("Logout functionality not initialized!");
        return;
    }

    const provider = yield getAuthProvider();
    const logoutRedirect = window.location.origin + "/auth/LogoutSuccess";

    if (provider == "UNIC") {
        window.open(logoutFullUrl, "unicLogoutWindow", getPopupWindowParams());
        window.location = logoutRedirect;
    } else {
        window.location = logoutFullUrl;
    }
}

function* userCreateSubmit(action) {
    try {
        const data = action.payload.userinfo;
        const url = action.payload.apiUrl || "/WebAPI/Feature/Profile/SaveUserProfile";
        const res = yield callServer(url, "POST", data);

        if (!res.Success) {
            action.payload.resolve(res);
            if (!res.Payload) {
                yield put(errorActions.addError(res.ErrorMessage));
            }
        } else {
            action.payload.resolve(res);
            yield call(createProfileTracking);
        }
    } catch (error) {
        yield put(errorActions.addError(error.message));
        action.payload.reject();
    } finally {
        yield put(profileActions.userCreateComplete());
    }
}

function* getNewsletterProfile(action) {
    try {
        const email = action.payload.email;
        const url = action.payload.apiUrl || "/WebAPI/Feature/Profile/GetNewsletterProfile";
        const res = yield call(callServer, url + "?email=" + email, "GET");

        if (res.Success) {
            // yield put(profileActions.getNewsletterProfileSuccess());
            action.payload.resolve(res);
        } else {
            // yield put(profileActions.getNewsletterProfileError());
            action.payload.reject(res);
            yield put(errorActions.addError(res.ErrorMessage));
        }
    } catch (error) {
        yield put(errorActions.addError(error.message));
    }
}

export default function* profileSaga() {
    yield all([
        fork(function* () {
            yield takeEvery(profileActions.initLogin.type, initLogin);
        }),
        fork(function* () {
            yield takeEvery(profileActions.userLogin.type, userLogin);
        }),
        fork(function* () {
            yield takeLatest(profileActions.unicConnect.type, unicConnect);
        }),
        fork(function* () {
            yield takeEvery(profileActions.userLoggedIn.type, userLoggedIn);
        }),
        fork(function* () {
            yield takeEvery(profileActions.userLogout.type, userLogout);
        }),
        fork(function* () {
            yield takeEvery(profileActions.userCreateSubmit.type, userCreateSubmit);
        }),
        fork(function* () {
            yield takeEvery(profileActions.getNewsletterProfile.type, getNewsletterProfile);
        })
    ]);
}
