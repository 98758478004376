import { fromJS } from "immutable";

export const articleTeaserListActions = {
    init: payload => {
        return { type: "ARTICLES_INIT", payload };
    },
    filterChanged: filterId => {
        return { type: "FILTER_CHANGED", filterId };
    },
    filterReset: () => {
        return { type: "FILTER_RESET" };
    },
    articlesLoadMore: () => {
        return { type: "ARTICLES_LOAD_MORE" };
    },
    articlesLoadSuccess: payload => {
        return { type: "ARTICLES_LOAD_SUCCESS", payload };
    },
    articlesLoadMoreSuccess: payload => {
        return { type: "ARTICLES_LOAD_MORE_SUCCESS", payload };
    },
    articlesLoadError: () => {
        return { type: "ARTICLES_LOAD_ERROR" };
    }
};

//The adding threads are all controlled in arrays.
const init = {
    filters: [],
    filterIsActive: null,
    articles: [],
    page: 0,
    currentTotalCount: 0,
    isLoadingMore: null
};

//Reducer
const articleTeaserListReducer = function (state = fromJS(init), action) {
    switch (action.type) {
        case "ARTICLES_INIT":
            if (action.payload.Filters) {
                state = state.set("filters", action.payload.Filters);
            }
            if (action.payload.Articles) {
                state = state
                    .set("articles", action.payload.Articles)
                    .set("pageSize", action.payload.PageSize)
                    .set("currentTotalCount", action.payload.TotalResults);
            }
            return state;

        case "FILTER_CHANGED": {
            const filters = state.get("filters");
            const filter = filters.filter(val => {
                return val.ItemId == action.filterId;
            });

            if (filter.length > 0) {
                filter[0].Enabled = !filter[0].Enabled;
                const filterIsActive =
                    filters.filter(val => {
                        return val.Enabled;
                    }).length > 0;
                return state.set("filters", filters).set("page", 0).set("filterIsActive", filterIsActive);
            } else {
                return state;
            }
        }
        case "FILTER_RESET": {
            const filters = state.get("filters");
            filters.map(filter => {
                filter.Enabled = false;
                return filter;
            });

            return state.set("filters", filters).set("page", 0).set("filterIsActive", false);
        }
        case "ARTICLES_LOAD_MORE": {
            const page = state.get("page");

            return state.set("isLoadingMore", true).set("page", page + 1);
        }

        case "ARTICLES_LOAD_SUCCESS":
            return state
                .set("articles", action.payload.Articles)
                .set("currentTotalCount", action.payload.TotalCount);

        case "ARTICLES_LOAD_MORE_SUCCESS": {
            const articles = state.get("articles");

            return state
                .set("isLoadingMore", false)
                .set("articles", articles.concat(action.payload.Articles));
        }

        default:
            return state;
    }
};

export const articleTeaserListSelector = {
    filters: state => state.articleTeaserList.get("filters"),
    filterIsActive: state => state.articleTeaserList.get("filterIsActive"),
    articles: state => state.articleTeaserList.get("articles"),
    page: state => state.articleTeaserList.get("page"),
    pageSize: state => state.articleTeaserList.get("pageSize"),
    isLoadingMore: state => state.articleTeaserList.get("isLoadingMore"),
    currentTotalCount: state => state.articleTeaserList.get("currentTotalCount")
};

export default articleTeaserListReducer;
