import { defineView } from "@/ReactSetup/view-definitions";

const definition = defineView({
    name: "MyDigitalProducts",
    component: () =>
        __SERVER__
            ? require("./profile-my-digital-products-view")
            : import("./profile-my-digital-products-view")
});

export default definition;
