import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "hooks/store-hooks";
import * as React from "react";
import IconUser from "ReactComponents/Shared/Icons/icon-user";
import { navigationActions, navigationSelector } from "ReactReducers/Navigation/navigation-reducer";
import { profileActions, profileSelector } from "ReactReducers/Profile/profile-reducer";
import { SiteName } from "../../../types";
import { ICreateProfileModalProps } from "../../CreateProfile/CreateProfileModal/create-profile-modal";

export interface INavigationProfileProps {
    labels: ICreateProfileModalProps["labels"];
    siteName: SiteName;
}

export function NavigationProfile() {
    const isLoggedIn = useAppSelector(profileSelector.loggedIn);
    const dispatch = useAppDispatch();
    const login = useAppSelector(profileSelector.login);
    const isOpen = useAppSelector(navigationSelector.isProfileDropdownOpen);
    const userProfile = useAppSelector(profileSelector.userInfo);

    if (!login && userProfile && Object.keys(userProfile).length === 0) {
        return null;
    }

    return (
        <>
            <div className="navigation-profile">
                <button
                    type="button"
                    aria-label="Menu for log ind eller opret bruger"
                    aria-expanded={isOpen}
                    className="navigation-profile__button button-reset"
                    onClick={() => dispatch(navigationActions.toggleProfileDropdown())}
                >
                    <IconUser />
                </button>
                {isLoggedIn ? <LoggedIn /> : <NotLoggedIn />}
                {/* {isLoggedIn ? <LoggedIn /> : <NotLoggedIn siteName={props.siteName} labels={props.labels} />} */}
            </div>
        </>
    );
}

export function LoggedIn() {
    const isOpen = useAppSelector(navigationSelector.isProfileDropdownOpen);
    const userProfile = useAppSelector(profileSelector.userInfo);
    const login = useAppSelector(profileSelector.login);

    if (!userProfile) {
        return null;
    }

    return (
        <List
            className={classNames("navigation-profile-list", {
                "navigation-profile-list--isOpen": isOpen
            })}
        >
            <>
                {userProfile.ProfileLinks?.map((link, index) => {
                    return <Item key={index} {...link} />;
                })}
                {login && <Item Text={login.LogoutUrl?.Text} Url={login.LogoutUrl?.FullUrl} />}
            </>
        </List>
    );
}

// interface INotLoggedInProps {
//     labels: ICreateProfileModalProps["labels"];
//     siteName: SiteName;
// }

export function NotLoggedIn() {
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector(navigationSelector.isProfileDropdownOpen);
    const login = useAppSelector(profileSelector.login);
    // const [isCreateProfileOpen, setIsCreateProfileOpen] = React.useState(false);

    if (!login) {
        return null;
    }

    return (
        <>
            <List
                {...login}
                className={classNames("navigation-profile-list", {
                    "navigation-profile-list--isOpen": isOpen
                })}
            >
                {login.LoginUrl && (
                    <Item onClick={() => dispatch(profileActions.userLogin())} Text={login.LoginUrl.Text} />
                )}
                <Item {...login.CreateProfileUrl} />
            </List>
        </>
    );
}

export function List(props: { className?: string; children: React.ReactNode }) {
    return (
        <div className={props.className}>
            <ul>{props.children}</ul>
        </div>
    );
}

export function Item(props: { Text?: string; Url?: string; onClick?(): void; className?: string }) {
    if (props.onClick) {
        return (
            <li>
                <button
                    type="button"
                    className={classNames("button-reset", props.className)}
                    onClick={props.onClick}
                >
                    {props.Text}
                </button>
            </li>
        );
    }

    return (
        <li className={props.className}>
            <a href={props.Url}>{props.Text}</a>
        </li>
    );
}
