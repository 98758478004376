export const orderTrialLicenseTracking = function () {
    pushEvent({
        event: "trackEvent",
        eventData: {
            category: "Form",
            action: "OrderTrialLicense",
            label: ""
        }
    });
};

export const createProfileTracking = function () {
    pushEvent({
        event: "trackEvent",
        eventData: {
            category: "Form",
            action: "CreateProfile",
            label: ""
        }
    });
};

export const growthHackTracking = function (eventAction) {
    pushEvent({
        event: "trackEvent",
        eventData: {
            category: "Form",
            action: eventAction,
            label: ""
        }
    });
};

export const subscribeToNewsletterTracking = function () {
    pushEvent({
        event: "trackEvent",
        eventData: {
            category: "Form",
            action: "SubscribeToNewsletter",
            label: ""
        }
    });
};

// Enhanced Ecommerce (EE)
// =======================

const generateEETrackingEvent = ({ event, action, actionField, products, currencyCode = "DKK" }) => {
    const trackingEvent = {
        event,
        ecommerce: {
            currencyCode,
            [action]: { products }
        }
    };

    if (actionField) {
        trackingEvent.ecommerce[action].actionField = actionField;
    }

    return trackingEvent;
};

// The product data varies slightly depending on where it comes from:
// Basket/mini-basket (product.Isbn, product.TypeTag)
// OR Product-list/-slider/-teaser (product.Id, product.MediaType)
const formattedProductProductClick = product => ({
    id: product.Isbn || product.Id,
    name: product.Title,
    category: product.TypeTag || product.MediaType,
    price: product.UnitPriceNumber
});

const formattedProductAddRemoveOnBasket = item => ({
    id: item.product.Isbn,
    name: item.product.Title,
    quantity: item.amount,
    category: item.product.InspectionCopy ? item.inspectionCopyLabel : item.product.TypeTag,
    licenseType: item.product.LicenseLabel || "",
    price: item.product.UnitPriceNumber
});

const formattedProductDirectlyFromBasket = (product, inspectionCopyLabel) => ({
    id: product.Isbn,
    name: product.Title,
    quantity: product.CalculatedCount,
    category: product.InspectionCopy ? inspectionCopyLabel : product.TypeTag,
    licenseType: product.LicenseLabel || "",
    price: product.UnitPriceNumber
});

export const productClick = ({ product, componentName }) => {
    const event = generateEETrackingEvent({
        event: "productClick",
        action: "click",
        actionField: {
            list: componentName
        },
        products: [formattedProductProductClick(product)]
    });

    pushEvent(event);
};

export const heroClick = ({ text, componentName }) => {
    const event = {
        event: "heroClick",
        action: "click",
        componentName,
        text
    };

    pushEvent(event);
};

export const productDetailsView = ({ product }) => {
    const event = {
        event: "productDetailsView",
        ecommerce: {
            detail: {
                products: [
                    {
                        id: product.Id,
                        category: product.MediaType,
                        name: product.Title
                    }
                ]
            }
        }
    };

    pushEvent(event);
};

export const addToBasket = ({ item }) => {
    const event = generateEETrackingEvent({
        event: "addToCart",
        action: "add",
        products: [formattedProductAddRemoveOnBasket(item)]
    });

    pushEvent(event);
};

export const removeFromBasket = ({ item }) => {
    const event = generateEETrackingEvent({
        event: "removeFromCart",
        action: "remove",
        products: [formattedProductAddRemoveOnBasket(item)]
    });

    pushEvent(event);
};

export const checkoutSteps = {
    BASKET: 1,
    CHECKOUT_LOGIN: 2,
    CHECKOUT_INFO: 3,
    CHECKOUT_CONFIRM: 4,
    CHECKOUT_START_PAYMENT: 5
};

export const checkout = ({ basket, stepNumber }) => {
    const products = basket.BasketItems.map(item =>
        formattedProductDirectlyFromBasket(item, basket.InspectionCopyLabel)
    );
    const event = generateEETrackingEvent({
        event: "checkout",
        action: "checkout",
        actionField: { step: stepNumber },
        products: products
    });

    pushEvent(event);
};

// A purchase event is sent on the receipt page
export const purchase = ({ basket }) => {
    const products = basket.BasketItems.map(item =>
        formattedProductDirectlyFromBasket(item, basket.InspectionCopyLabel)
    );
    const event = generateEETrackingEvent({
        event: "purchase",
        action: "purchase",
        actionField: {
            id: basket.OrderId,
            revenue: basket.Summation.FinalPriceNumber,
            tax: basket.Summation.VATNumber,
            shipping: basket.Summation.BookingFeeNumber,
            coupon: basket.PromotionCode
        },
        products: products
    });

    pushEvent(event);
};

const pushEvent = event => {
    if (window.dataLayer) {
        window.dataLayer.push(event);
    }
};
