import React, { Component } from "react";
import Modal from "ReactComponents/Modal/modal";

/**
 * @param IsOpen (boolean) init visible or not
 * @param RichText (string) rich text to be rendered
 *
 */
class ModalRichTextAlert extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: props.IsOpen
        };

        this.onCloseHandler = this.onCloseHandler.bind(this);
    }

    onCloseHandler() {
        this.setState({
            isOpen: false
        });
    }

    render() {
        return (
            <Modal
                isOpen={this.state.isOpen}
                toClose={() => {
                    this.onCloseHandler();
                }}
                showCloseButton
            >
                <div className="content--inline-block content-block content-inner content--white">
                    <div
                        className="content-rich-text"
                        dangerouslySetInnerHTML={{ __html: this.props.RichText }}
                    />
                </div>
            </Modal>
        );
    }
}

export default ModalRichTextAlert;
