import { fromJS } from "immutable";

//Actions
export const trialLicenseActions = {
    trialLicenseInit: Payload => {
        return { type: "TRIAL_LICENSE_INIT", Payload };
    },
    trialLicenseRequest: Payload => {
        return { type: "TRIAL_LICENSE_REQUEST", Payload };
    },
    trialLicenseRequestSuccess: () => {
        return { type: "TRIAL_LICENSE_REQUEST_SUCCESS" };
    },
    trialLicenseUpdate: Payload => {
        return { type: "TRIAL_LICENSE_UPDATE", Payload };
    },
    trialLicenseValidate: Payload => {
        return { type: "TRIAL_LICENSE_VALIDATE", Payload };
    },
    trialLicenseValidateSuccess: () => {
        return { type: "TRIAL_LICENSE_VALIDATE_SUCCESS" };
    },
    trialLicenseError: ErrorMessage => {
        return { type: "TRIAL_LICENSE_ERROR", ErrorMessage };
    },
    trialLicensePending: () => {
        return { type: "TRIAL_LICENSE_PENDING" };
    },
    trialLicenseComplete: () => {
        return { type: "TRIAL_LICENSE_COMPLETE" };
    }
};

//Reducer initial state
const init = {
    pending: false,
    validateSuccess: false,
    requestSuccess: false,
    error: false,
    errorMessage: null,
    licenseStatus: {}
};

//Reducer
export const trialLicenseReducer = function (state = fromJS(init), action) {
    switch (action.type) {
        case "TRIAL_LICENSE_INIT":
            return state;

        case "TRIAL_LICENSE_REQUEST":
            return state;

        case "TRIAL_LICENSE_PENDING":
            return state
                .set("pending", true)
                .set("validateSuccess", false)
                .set("requestSuccess", false)
                .set("error", false);

        case "TRIAL_LICENSE_VALIDATE_SUCCESS":
            return state.set("validateSuccess", true);

        case "TRIAL_LICENSE_REQUEST_SUCCESS":
            return state.set("requestSuccess", true);

        case "TRIAL_LICENSE_ERROR":
            return state.set("error", true).set("pending", false).set("errorMessage", action.ErrorMessage);

        case "TRIAL_LICENSE_COMPLETE":
            return state.set("pending", false);

        case "TRIAL_LICENSE_UPDATE":
            return state.set("licenseStatus", action.Payload);

        default:
            return state;
    }
};

//Selector
export const trialLicenseSelector = {
    trialLicenseStatus: state => state.trialLicense.get("licenseStatus"),
    trialLicensePending: state => state.trialLicense.get("pending"),
    trialLicenseValidateSuccess: state => state.trialLicense.get("validateSuccess"),
    trialLicenseRequestSuccess: state => state.trialLicense.get("requestSuccess"),
    trialLicenseError: state => state.trialLicense.get("error"),
    trialLicenseErrorMessage: state => state.trialLicense.get("errorMessage")
};
