import useAsync, { IUseAsyncOptions } from "hooks/useAsync";
import { endpoints } from "ReactApi/endpoints";
import { callServer, SitecoreResponse, SiteCoreErrors } from "ReactApi/serverService";

export const sendVerificationEmail = async () => {
    const response = await callServer<boolean>(endpoints.sendVerificationEmail, "GET");

    if (!response.Success) {
        throw response as unknown as SitecoreResponse<SiteCoreErrors<boolean>>;
    }

    return response;
};

export function useSendVerificationEmail(options?: Omit<IUseAsyncOptions, "immediate">) {
    return useAsync<SitecoreResponse<boolean>, SitecoreResponse<SiteCoreErrors<boolean>>>(
        sendVerificationEmail,
        {
            immediate: false,
            ...options
        }
    );
}
