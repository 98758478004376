global.canUseDOM = !(typeof window == "undefined");
import { clientInitializer } from "@/ReactSetup/client-initializer";
import { serverInitializer } from "@/ReactSetup/server-initializer";

if (__SERVER__) {
    serverInitializer();
}

if (!__SERVER__) {
    clientInitializer();
}
