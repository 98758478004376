import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "types";

export interface IOverlayState {
    visible: boolean;
}

const initialState = {
    visible: false
};

const overlaySlice = createSlice({
    name: "overlay",
    initialState,
    reducers: {
        clicked: () => {
            return;
        }
    }
});

export const overlaySelector = {
    visible: (state: RootState) => {
        if (typeof state.navigation.activeMenu !== "undefined") {
            return true;
        }

        if (state.overlay.visible) {
            return true;
        }

        if (state.navigation.isProfileDropdownOpen) {
            return true;
        }

        if (state.navigation.isMobileMenuOpen) {
            return true;
        }

        if (state.navigation.isSearchOpen) {
            return true;
        }

        return false;
    }
};

export const overlayActions = overlaySlice.actions;

export default overlaySlice.reducer;
