import { RootState } from "types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBasket, IBasketProduct } from "ReactComponents/Navigation/navigation-types";

export interface IBasketState {
    isAdding: string[];
    isRemoving: string[];
    isSettingQuatity: string[];
    isSettingClasses: string[];
    basket: Partial<IBasket> | undefined;
    discountCodePending: boolean | undefined;
    discountCodeSuccess: boolean | undefined;
    discountCodeError: boolean | undefined;
    discountCodeComplete: boolean | undefined;
}

const initialState: IBasketState = {
    isAdding: [],
    isRemoving: [],
    isSettingQuatity: [],
    isSettingClasses: [],
    basket: {},
    discountCodePending: undefined,
    discountCodeSuccess: undefined,
    discountCodeError: undefined,
    discountCodeComplete: undefined
};

function addIfNotPresent<T>(value: T, self: T[]) {
    const isPresent = self.indexOf(value) > -1;
    if (isPresent) {
        return self;
    }
    return [...self, value];
}

type AddToBasketPayload = {
    AddCountToExistingBasket: boolean;
    Id: string;
    amount: number;
    product: IBasketProduct;
};

type RemoveFromBasketPayload = string;

type SetClassesPayload = { id: string; classes: string[] };

type SetQuantityPayload = { id: string; quantity: number };

const basketSlice = createSlice({
    name: "basket",
    initialState,
    reducers: {
        init: (state, action: PayloadAction<IBasket>) => {
            state.basket = action.payload;
        },
        basketAdd: (state, action: PayloadAction<AddToBasketPayload>) => {
            state.isAdding = addIfNotPresent(action.payload.Id, state.isAdding);
        },
        basketAddSuccess: (state, action: PayloadAction<IBasket>) => {
            state.basket = action.payload;
        },
        basketAddComplete: (state, action: PayloadAction<AddToBasketPayload>) => {
            state.isAdding = state.isAdding.filter(id => action.payload.Id !== id);
        },
        basketRemove: (state, action: PayloadAction<RemoveFromBasketPayload>) => {
            state.isRemoving = addIfNotPresent(action.payload, state.isRemoving);
        },
        basketRemoveComplete: (state, action: PayloadAction<RemoveFromBasketPayload>) => {
            state.isRemoving = state.isRemoving.filter(id => action.payload !== id);
        },
        basketSetQuantity: (state, action: PayloadAction<SetQuantityPayload>) => {
            state.isSettingQuatity = addIfNotPresent(action.payload.id, state.isSettingQuatity);
        },
        basketSetQuantityComplete: (state, action: PayloadAction<SetQuantityPayload>) => {
            state.isSettingQuatity = state.isSettingQuatity.filter(id => action.payload.id !== id);
        },
        basketSetClassesValue: (state, action: PayloadAction<SetClassesPayload>) => {
            state.isSettingClasses = addIfNotPresent(action.payload.id, state.isSettingClasses);
        },
        basketSetClassesValueComplete: (state, action: PayloadAction<SetClassesPayload>) => {
            state.isSettingClasses = state.isSettingClasses.filter(id => action.payload.id !== id);
        },
        discountCodeSubmit: (state, _action: PayloadAction<{ formInfo: any; resolve: any; reject: any }>) => {
            state.discountCodePending = true;
        },
        discountCodeRemove: state => {
            state.discountCodePending = true;
        },
        discountCodeSuccess: (state, action: PayloadAction<IBasket>) => {
            state.discountCodeSuccess = true;
            state.basket = action.payload;
        },
        discountCodeError: state => {
            state.discountCodeError = true;
        },
        discountCodeComplete: state => {
            state.discountCodePending = false;
            state.discountCodeComplete = true;
        }
    }
});

export const basketActions = basketSlice.actions;

export const basketSelector = {
    isAdding: (state: RootState) => state.basket.isAdding,
    isRemoving: (state: RootState) => state.basket.isRemoving,
    isSettingQuatity: (state: RootState) => state.basket.isSettingQuatity,
    isSettingClasses: (state: RootState) => state.basket.isSettingClasses,
    basket: (state: RootState) => state.basket.basket,
    discountCodePending: (state: RootState) => state.basket.discountCodePending,
    discountCodeSuccess: (state: RootState) => state.basket.discountCodeSuccess,
    discountCodeError: (state: RootState) => state.basket.discountCodeError,
    discountCodeComplete: (state: RootState) => state.basket.discountCodeComplete
};

export default basketSlice.reducer;
