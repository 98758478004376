import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { all, fork } from "redux-saga/effects";

//Get reducers
import { reducer as formReducer } from "redux-form";
import articleTeaserListReducer from "./ReactReducers/ArticleTeaserList/article-teaser-list-reducer";
import basketReducer from "./ReactReducers/Basket/basket-reducer";
import contactFormReducer from "./ReactReducers/ContactForm/contact-form-reducer";
import checkoutReducer from "./ReactReducers/Checkout/checkout-reducer";
import errorReducer from "./ReactReducers/Error/error-reducer";
import navigationReducer from "./ReactReducers/Navigation/navigation-reducer";
import newsletterReducer from "./ReactReducers/Newsletter/newsletter-reducer";
import productList from "./ReactReducers/ProductList/product-list-reducer";
import productPickerReducer from "./ReactReducers/ProductPicker/product-picker-reducer";
import profileReducer from "./ReactReducers/Profile/profile-reducer";
import overlayReducer from "./ReactReducers/Overlay/overlay-reducer";
import searchReducer from "./ReactReducers/Search/search-reducer";
import ordersReducer from "./ReactReducers/Orders/orders-reducer";
import systemListReducer from "./ReactReducers/SystemList/system-list-reducer";
import teacherSampleFormReducer from "./ReactReducers/TeacherSampleForm/teacher-sample-form-reducer";
import { trialLicenseReducer } from "./ReactReducers/TrialLicense/trial-license-reducer";
import updatePaymentReducer from "./ReactReducers/UpdatePayment/update-payment-reducer";

import articleTeaserListSaga from "./ReactReducers/ArticleTeaserList/article-teaser-list-sagas";
import basketSaga from "./ReactReducers/Basket/basket-sagas";
import contactFormSaga from "./ReactReducers/ContactForm/contact-form-sagas";
import checkoutSaga from "./ReactReducers/Checkout/checkout-sagas";
import newsletterSaga from "./ReactReducers/Newsletter/newsletter-sagas";
import productSaga from "./ReactReducers/ProductList/product-list-sagas";
import productPickerSaga from "./ReactReducers/ProductPicker/product-picker-sagas";
import profileSaga from "./ReactReducers/Profile/profile-sagas";
import searchSaga from "./ReactReducers/Search/search-sagas";
import ordersSaga from "./ReactReducers/Orders/orders-sagas";
import systemListSaga from "./ReactReducers/SystemList/system-list-sagas";
import teacherSampleFormSaga from "./ReactReducers/TeacherSampleForm/teacher-sample-form-sagas";
import trialLicenseSaga from "./ReactReducers/TrialLicense/trial-license-sagas";
import updatePaymentSaga from "./ReactReducers/UpdatePayment/update-payment-sagas";

const sagaMiddleware = createSagaMiddleware();

function* rootSaga() {
    yield all([
        fork(productSaga),
        fork(profileSaga),
        fork(newsletterSaga),
        fork(basketSaga),
        fork(contactFormSaga),
        fork(productPickerSaga),
        fork(checkoutSaga),
        fork(articleTeaserListSaga),
        fork(searchSaga),
        fork(ordersSaga),
        fork(systemListSaga),
        fork(teacherSampleFormSaga),
        fork(trialLicenseSaga),
        fork(updatePaymentSaga)
    ]);
}

const store = configureStore({
    reducer: {
        navigation: navigationReducer,
        productList: productList,
        profile: profileReducer,
        newsletter: newsletterReducer,
        basket: basketReducer,
        contactForm: contactFormReducer,
        errors: errorReducer,
        productPicker: productPickerReducer,
        form: formReducer,
        overlay: overlayReducer,
        articleTeaserList: articleTeaserListReducer,
        search: searchReducer,
        orders: ordersReducer,
        systemList: systemListReducer,
        teacherSampleForm: teacherSampleFormReducer,
        trialLicense: trialLicenseReducer,
        checkout: checkoutReducer,
        updatePayment: updatePaymentReducer
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({ thunk: false, serializableCheck: false }).concat(sagaMiddleware)
});

sagaMiddleware.run(rootSaga);

export default store;
