export const endpoints = {
    getInstitutions: "WebAPI/Feature/Profile/GetInstitutions",
    activateDigitalProduct: "WebAPI/Feature/License/ActivateDigitalProduct",
    activateEkey: "WebAPI/Feature/Profile/ActivateEkey",
    changePassword: "WebAPI/Feature/Profile/ChangePassword",
    saveUserProfile: "WebAPI/Feature/Profile/SaveUserProfile",
    removeUnicConnection: "WebApi/Feature/Profile/RemoveUnicConnection",
    sendVerificationEmail: "WebAPI/Feature/Profile/ResendActivationEmail",
    sendVerificationEmailWithToken: "WebAPI/Feature/Profile/ResendActivationEmail",
    getOrder: "WebAPI/Feature/Order/GetOrder",
    getOrders: "WebAPI/Feature/Order/GetOrders",
    getMyDigitalProducts: "WebAPI/Feature/License/GetMyDigitalProducts"
};
