import React, { Component } from "react";
import IconClose from "../Shared/Icons/icon-close";
import { Transition } from "react-transition-group";
import classNames from "classnames";

class Error extends Component {
    constructor() {
        super();
    }

    componentDidMount() {
        if (!this.props.ttl) return;

        this.timeout = setTimeout(() => {
            this.clear();
        }, this.props.ttl);
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    clear() {
        this.props.onClear({ id: this.props.id });
    }

    render() {
        return (
            <Transition mountOnEnter unmountOnExit timeout={450} {...this.props}>
                {state => (
                    <button
                        type="button"
                        id={this.props.id}
                        onClick={() => this.clear()}
                        className={classNames("app-errors__error", state)}
                    >
                        <p dangerouslySetInnerHTML={{ __html: this.props.text }}></p>
                        <div className="app-errors__error__close ">
                            <IconClose />
                        </div>
                    </button>
                )}
            </Transition>
        );
    }
}

export default Error;
