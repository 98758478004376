import { defineView } from "@/ReactSetup/view-definitions";
import { systemListActions } from "ReactReducers/SystemList/system-list-reducer";

const definition = defineView({
    name: "SystemList",
    component: () => (__SERVER__ ? require("./system-list-view") : import("./system-list-view")),
    setup: payload => {
        return systemListActions.init(payload);
    }
});

export default definition;
