import { productListActions } from "@/ReactReducers/ProductList/product-list-reducer";
import { defineView } from "@/ReactSetup/view-definitions";

const definition = defineView({
    name: "ProductList",
    component: () => (__SERVER__ ? require("./product-list-view") : import("./product-list-view")),
    setup: productListActions.setup
});

export default definition;
