import { RootState } from "../../types";
import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { overlayActions } from "ReactReducers/Overlay/overlay-reducer";

export interface INavigationState {
    activeMenu: number | undefined;
    activeSubMenu: number | undefined;
    isMobileMenuOpen: boolean;
    isProfileDropdownOpen: boolean;
    isSearchOpen: boolean;
}

const initialState: INavigationState = {
    activeMenu: undefined,
    activeSubMenu: undefined,
    isMobileMenuOpen: false,
    isProfileDropdownOpen: false,
    isSearchOpen: false
};

const navigationSlice = createSlice({
    name: "navigation",
    initialState,
    reducers: {
        setActiveMenu: (state, action: PayloadAction<number | undefined>) => {
            const currentState = current(state);
            if (action.payload === currentState.activeMenu) {
                state.activeMenu = undefined;
            } else {
                state.activeMenu = action.payload;
            }
            state.isProfileDropdownOpen = false;
            state.isSearchOpen = false;
        },
        setActiveSubMenu: (state, action: PayloadAction<number>) => {
            state.activeSubMenu = action.payload;
            state.isProfileDropdownOpen = false;
        },
        resetMenu: state => {
            state.activeMenu = undefined;
            state.activeSubMenu = undefined;
        },
        resetSubMenu: state => {
            state.activeSubMenu = undefined;
        },
        setActiveMobileMenu: (state, action: PayloadAction<boolean>) => {
            state.isMobileMenuOpen = action.payload;
            state.isProfileDropdownOpen = false;
        },
        openProfileDropdown: () => {
            return { ...initialState, isProfileDropdownOpen: true };
        },
        closeProfileDropdown: state => {
            state.isProfileDropdownOpen = false;
        },
        toggleProfileDropdown: state => {
            return { ...initialState, isProfileDropdownOpen: !state.isProfileDropdownOpen };
        },
        openMobileMenu: state => {
            state.isMobileMenuOpen = true;
            state.isSearchOpen = false;
        },
        closeMobileMenu: state => {
            state.isMobileMenuOpen = false;
        },
        toggleMobileMenu: state => {
            if (state.isMobileMenuOpen) {
                return initialState;
            }
            state.isMobileMenuOpen = true;
            state.isSearchOpen = false;
        },
        openSearch: () => {
            return { ...initialState, isSearchOpen: true };
        },
        closeSearch: state => {
            state.isSearchOpen = false;
        },
        toggleSearch: state => {
            return { ...initialState, isSearchOpen: !state.isSearchOpen };
        },
        reset: () => {
            return initialState;
        }
    },
    extraReducers: builder => {
        builder.addCase(overlayActions.clicked, () => {
            return initialState;
        });
    }
});

export const navigationActions = navigationSlice.actions;

export const navigationSelector = {
    isProfileDropdownOpen: (state: RootState) => state.navigation.isProfileDropdownOpen,
    isMenuOpen: (state: RootState) => typeof state.navigation.activeMenu !== "undefined",
    activeMenu: (state: RootState) => state.navigation.activeMenu,
    activeSubMenu: (state: RootState) => state.navigation.activeSubMenu,
    isMobileMenuOpen: (state: RootState) => state.navigation.isMobileMenuOpen,
    isSearchOpen: (state: RootState) => state.navigation.isSearchOpen
};

export default navigationSlice.reducer;
