import * as React from "react";
import { navigationSelector, navigationActions } from "../../ReactReducers/Navigation/navigation-reducer";
import { NavigationMenu, NavigationMenuItem } from "./navigation-menu";
import { useAppDispatch, useAppSelector } from "../../hooks/store-hooks";
import classNames from "classnames";
import { ISearch, IBreadcrumbs } from "./navigation-types";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import IconSearch from "../Shared/Icons/icon-search";
import { INavigationProfileProps, NavigationProfile } from "./navigation-profile/navigation-profile";
import { NavigationBasket } from "./navigation-basket/navigation-basket";
import { NavigationMobileProfile } from "./navigation-mobile-profile/navigation-mobile-profile";
import { NavigationSearch } from "./navigation-search/navigation-search";
import { SiteName } from "../../types";
import AccountVerificationMissingModal from "ReactComponents/AccountVerificationMissingModal/account-verification-missing-modal";
import { profileSelector } from "ReactReducers/Profile/profile-reducer";
import getSiteName from "js/utils/site-name";

export interface INavigationProps {
    Logo: string;
    Menu: INavigationList;
    Search: ISearch;
    ServiceMenu: INavigationList;
    Breadcrumbs: IBreadcrumbs; // todo
    CreateProfileLabels: INavigationProfileProps["labels"];
    SiteName: SiteName;
}

export interface INavigationList {
    Id: string;
    Children: INavigationList[];
    Label: string | null;
    Theme: string | null;
    Url: string | null;
    ShowInMenu: boolean;
    ShowInBreadcrumbs: boolean;
}

function isHeaderItem(item: INavigationList) {
    return Boolean(item.ShowInMenu);
}

interface INavigationHeaderItemProps extends INavigationList {
    index?: number;
    activeMenuIndex?: number;
}

function NavigationHeaderItem(props: INavigationHeaderItemProps) {
    const dispatch = useAppDispatch();

    function openMenu() {
        dispatch(navigationActions.setActiveMenu(props.index));
    }

    const Wrapper: React.FC = wrapperProps =>
        props.Children?.length ? (
            <button
                onClick={openMenu}
                className={classNames("navigation-header-item__button", {
                    "navigation-header-item__button--isActive": props.activeMenuIndex === props.index
                })}
                type="button"
                {...wrapperProps}
            />
        ) : props.Url ? (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a href={props.Url} className="navigation-header-item__link" {...wrapperProps} />
        ) : null;

    return (
        <li className="navigation-header-item">
            <Wrapper>{props.Label}</Wrapper>
        </li>
    );
}

export function Navigation(props: INavigationProps) {
    const dispatch = useAppDispatch();

    const accountVerificationLabels = useAppSelector(profileSelector.verificationModal);
    const isMobileMenuOpen = useAppSelector(navigationSelector.isMobileMenuOpen);
    const isSearchOpen = useAppSelector(navigationSelector.isSearchOpen);
    const activeMenuIndex = useAppSelector(navigationSelector.activeMenu);
    const activeSubmenuIndex = useAppSelector(navigationSelector.activeSubMenu);

    const headerItems = props.Menu.Children.filter(isHeaderItem).map((item, index) => (
        <NavigationHeaderItem key={index} activeMenuIndex={activeMenuIndex} index={index} {...item} />
    ));

    const mobileMenuItems = React.useMemo(
        () =>
            props.Menu.Children.filter(isHeaderItem).map((item, index) => (
                <NavigationMenuItem
                    onClick={() => {
                        dispatch(navigationActions.setActiveMenu(index));
                    }}
                    key={item.Id}
                    isActive={index === activeMenuIndex}
                    index={index}
                    enableTabIndexMobile={
                        !(Number.isInteger(activeMenuIndex) && typeof activeSubmenuIndex === "undefined")
                    }
                    disabled={Number.isInteger(activeMenuIndex) && typeof activeSubmenuIndex === "undefined"}
                    {...item}
                />
            )),
        [activeMenuIndex]
    );

    function toggleMobileMenu() {
        dispatch(navigationActions.toggleMobileMenu());
    }

    function goBackOrClose(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.stopPropagation();
        if (typeof activeSubmenuIndex !== "undefined") {
            return dispatch(navigationActions.resetSubMenu());
        }
        if (typeof activeMenuIndex !== "undefined") {
            return dispatch(navigationActions.resetMenu());
        }
        dispatch(navigationActions.closeMobileMenu());
    }

    function onSearchClick() {
        if (window.innerWidth < 600) {
            window.location.pathname = "/soeg";
            return;
        }
        dispatch(navigationActions.toggleSearch());
    }

    return (
        <>
            <a className="navigation-header__skip-link" href="#main-content">
                Spring til hovedindhold
            </a>
            {props.Search && <NavigationSearch Placeholder={props.Search.Placeholder} />}
            <header
                className={classNames("navigation-header", {
                    "navigation-header--mobileMenuIsOpen": isMobileMenuOpen
                })}
            >
                <nav role="navigation" aria-label="Hovedmenu" className="navigation-header__inner">
                    <button
                        aria-label={`${isMobileMenuOpen ? "Luk menu" : "Åbn menu"}`}
                        aria-expanded={isMobileMenuOpen}
                        onClick={toggleMobileMenu}
                        type="button"
                        className="navigation-header__menu-button"
                    >
                        <svg
                            className={classNames("navigation-header__mobile-menu-button", {
                                "navigation-header__mobile-menu-button--isActive": isMobileMenuOpen
                            })}
                            width="23"
                            height="18"
                            viewBox="0 0 23 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <line
                                x1="3"
                                y1="1"
                                x2="22"
                                y2="1"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                            />
                            <line
                                x1="3"
                                y1="9"
                                x2="22"
                                y2="9"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                            />
                            <line
                                x1="3"
                                y1="17"
                                x2="22"
                                y2="17"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                            />
                        </svg>
                    </button>
                    {/* TODO: Get BE to send image source, not raw HTML? */}
                    <a href="/" className="navigation-header__logo">
                        <img src={props.Logo} alt={`${getSiteName(props.SiteName)} logo`} />
                    </a>
                    <ul className="navigation-header__items">{headerItems}</ul>
                    <div className="navigation-header__service-menu">
                        {props.ServiceMenu?.Url && (
                            <a className="navigation-header__service-menu-link" href={props.ServiceMenu.Url}>
                                {props.ServiceMenu.Label}
                            </a>
                        )}
                        {props.Search && (
                            <button
                                type="button"
                                aria-label={`${isSearchOpen ? "Luk søgning" : "Åbn Søgning"}`}
                                aria-expanded={isSearchOpen}
                                onClick={onSearchClick}
                                className="navigation-header__button button-reset"
                            >
                                <IconSearch />
                            </button>
                        )}
                        <NavigationBasket />
                        <NavigationProfile siteName={props.SiteName} labels={props.CreateProfileLabels} />
                    </div>
                    <button
                        aria-label="Gå tilbage"
                        className="navigation-mobile__back-button"
                        type="button"
                        onClick={goBackOrClose}
                    >
                        <svg
                            width="9"
                            height="14"
                            viewBox="0 0 9 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M1.5006 0.999884L7.15746 6.65674L1.5006 12.3136" stroke="currentColor" />
                        </svg>
                    </button>
                    <div
                        className={classNames("navigation-mobile", {
                            "navigation-mobile--isOpen": isMobileMenuOpen
                        })}
                    >
                        <span className="navigation-mobile__title">Menu</span>
                        <ul className="navigation-mobile__links">{mobileMenuItems}</ul>
                        <hr />
                        {props.CreateProfileLabels && (
                            <NavigationMobileProfile
                                labels={props.CreateProfileLabels}
                                siteName={props.SiteName}
                            />
                        )}
                    </div>
                    <TransitionGroup component={null}>
                        {props.Menu.Children.map((child, index) => {
                            if (index !== activeMenuIndex) {
                                return null;
                            }
                            return (
                                <CSSTransition in timeout={500} key={child.Id} classNames="nav-menu">
                                    <NavigationMenu key={child.Id} {...child} />
                                </CSSTransition>
                            );
                        })}
                    </TransitionGroup>
                </nav>
            </header>
            {accountVerificationLabels && (
                <AccountVerificationMissingModal labels={accountVerificationLabels} />
            )}
        </>
    );
}
