import { defineView } from "@/ReactSetup/view-definitions";
import { basketActions } from "ReactReducers/Basket/basket-reducer";
import { profileActions } from "ReactReducers/Profile/profile-reducer";

const definition = defineView({
    name: "AppReduxInit",
    component: () => require("./app-redux-init-view"),
    setup: [
        payload => {
            console.log("payload.Basket:", payload.Basket);
            return basketActions.init(payload.Basket);
        },
        payload => {
            return profileActions.initLogin({
                UserProfile: payload.UserProfile,
                Login: payload.Login,
                AccountVerificationModalLabels: payload.AccountVerificationModalLabels
            });
        }
    ]
});

export default definition;
