const getSiteName = siteName => {
    switch (siteName) {
        case "gu":
            return "Gyldendal Uddanelse";
        case "hansreitzel":
            return "Hans Reitzels Forlag";
        case "munksgaard":
            return "Forlaget Munksgaard";
        default:
            return "";
    }
};

export default getSiteName;
