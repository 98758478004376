import * as React from "react";
import { createPortal } from "react-dom";

const KEYCODES = {
    ESCAPE: 27
};

interface IPortalProps {
    isOpen: boolean;
    onOpen?(): void;
    onClose?(): void;
    closeOnEsc?: boolean;
    closeOnOutsideClick?: boolean;
    children: React.ReactElement;
}

export default function Portal(props: IPortalProps) {
    const nodeRef = React.useRef<HTMLDivElement>(null);

    function open() {
        props.onOpen?.();
    }

    function close() {
        props.onClose?.();
    }

    React.useEffect(() => {
        if (props.isOpen) {
            open();
        }
    }, [props.isOpen]);

    function handleOutsideMouseClick(e: MouseEvent | TouchEvent) {
        if (!props.isOpen) {
            return;
        }

        if (nodeRef.current?.contains(e.target as Node)) {
            return;
        }

        e.stopPropagation();
        close();
    }

    function handleKeydown(e: KeyboardEvent) {
        if (e.keyCode === KEYCODES.ESCAPE || e.key === "Escape" || e.code === "Escape") {
            close();
        }
    }

    React.useEffect(() => {
        if (props.closeOnEsc) {
            document.addEventListener("keydown", handleKeydown);
        }

        if (props.closeOnOutsideClick) {
            document.addEventListener("mouseup", handleOutsideMouseClick);
            document.addEventListener("touchstart", handleOutsideMouseClick);
        }

        if (props.isOpen) {
            document.body.classList.add("locked-scroll");
        }

        return () => {
            document.body.classList.remove("locked-scroll");
            document.removeEventListener("keydown", handleKeydown);
            document.removeEventListener("mouseup", handleOutsideMouseClick);
            document.removeEventListener("touchstart", handleOutsideMouseClick);
        };
    }, [props.isOpen]);

    if (!props.isOpen) {
        return null;
    }

    if (typeof document === "undefined") return null;

    const root = document?.getElementById("portal");

    if (!root) {
        return null;
    }

    return createPortal(<div ref={nodeRef}>{props.children}</div>, root);
}
