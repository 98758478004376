import * as React from "react";
import { useBasket } from "ReactReducers/Basket/basket-hooks";
// import { IBasket } from "../navigation-types";
import IconCart from "ReactComponents/Shared/Icons/icon-cart";

// interface INavigationBasketProps {
//     Basket: IBasket;
// }

export function NavigationBasket() {
    const { basket } = useBasket();

    // In order to prevent the browser from caching the basket count we need to defer the rendering, because of SSR, I think.
    const [didMount, setDidMount] = React.useState(false);

    React.useEffect(() => {
        setDidMount(true);
    }, []);

    if (!didMount) return null;

    if (!basket) {
        return null;
    }

    const isNonEmpty = basket.TotalItemCount && basket.TotalItemCount > 0;

    if (!isNonEmpty) {
        return null;
    }

    return (
        <a className="navigation-basket" href={basket.BasketPageUrl}>
            <span className="navigation-basket__inner">
                <span className="navigation-basket__count">{basket.TotalItemCount}</span>
                <span className="sr-only"> produkter i kurv</span>
                <IconCart />
            </span>
        </a>
    );
}
