// @ts-check
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "types";

export const errorSelector = {
    errors: (state: RootState) => {
        return state.errors;
    }
};

type Error = {
    id: number;
    text: string;
};

const initialState: Error[] = [];

const errorSlice = createSlice({
    name: "errors",
    initialState,
    reducers: {
        addError: (state, action: PayloadAction<string>) => {
            state.push({ id: Date.now(), text: action.payload });
        },
        clearError: (state, action: PayloadAction<{ id: number }>) => {
            return state.filter(error => error.id !== action.payload.id);
        }
    }
});

export const errorActions = errorSlice.actions;

export default errorSlice.reducer;
