import { fromJS } from "immutable";

export const systemListActions = {
    init: payload => {
        return { type: "SYSTEMS_INIT", payload };
    },
    systemsLoadMore: () => {
        return { type: "SYSTEMS_LOAD_MORE" };
    },
    systemsLoadSuccess: payload => {
        return { type: "SYSTEMS_LOAD_SUCCESS", payload };
    },
    systemsLoadMoreSuccess: payload => {
        return { type: "SYSTEMS_LOAD_MORE_SUCCESS", payload };
    },
    systemsLoadError: errorMessage => {
        return { type: "SYSTEMS_LOAD_ERROR", errorMessage };
    },
    systemsSort: orderDir => {
        return { type: "SYSTEMS_SORT", orderDir };
    }
};

//The adding threads are all controlled in arrays.
const init = {
    systems: [],
    page: 0,
    hasNext: null,
    totalCount: null,
    orderDir: "asc",
    isLoading: null,
    isLoadingMore: null
};

//Reducer
const systemListReducer = function (state = fromJS(init), action) {
    switch (action.type) {
        case "SYSTEMS_INIT":
            return state
                .set("isLoading", true)
                .set("pageSize", action.payload.Payload.PageSize)
                .set("systemId", action.payload.Payload.SystemId)
                .set("itemId", action.payload.Payload.ItemId);

        case "SYSTEMS_LOAD_SUCCESS":
            return state
                .set("isLoading", false)
                .set("systems", action.payload.Items)
                .set("hasNext", action.payload.HasNext)
                .set("totalCount", action.payload.TotalCount);

        case "SYSTEMS_LOAD_ERROR":
            return state.set("errorMessage", action.errorMessage);

        case "SYSTEMS_LOAD_MORE":
            return state.set("isLoadingMore", true).set("page", state.get("page") + 1);

        case "SYSTEMS_LOAD_MORE_SUCCESS":
            return state
                .set("isLoadingMore", false)
                .set("systems", state.get("systems").concat(action.payload.Items))
                .set("hasNext", action.payload.HasNext);

        case "SYSTEMS_SORT":
            return state.set("isLoading", true).set("page", 0).set("orderDir", action.orderDir);

        default:
            return state;
    }
};

export const systemListSelector = {
    systems: state => state.systemList.get("systems"),
    page: state => state.systemList.get("page"),
    pageSize: state => state.systemList.get("pageSize"),
    itemId: state => state.systemList.get("itemId"),
    systemId: state => state.systemList.get("systemId"),
    hasNext: state => state.systemList.get("hasNext"),
    isLoading: state => state.systemList.get("isLoading"),
    isLoadingMore: state => state.systemList.get("isLoadingMore"),
    totalCount: state => state.systemList.get("totalCount"),
    errorMessage: state => state.systemList.get("errorMessage"),
    orderDir: state => state.systemList.get("orderDir")
};

export default systemListReducer;
