import { fromJS } from "immutable";

export const ordersActions = {
    init: payload => {
        return { type: "ORDERS_INIT", payload };
    },
    ordersLoadMore: () => {
        return { type: "ORDERS_LOAD_MORE" };
    },
    ordersLoadSuccess: payload => {
        return { type: "ORDERS_LOAD_SUCCESS", payload };
    },
    ordersLoadMoreSuccess: payload => {
        return { type: "ORDERS_LOAD_MORE_SUCCESS", payload };
    },
    ordersLoadError: errorMessage => {
        return { type: "ORDERS_LOAD_ERROR", errorMessage };
    },
    submitEkey: ekey => {
        return { type: "ORDERS_EKEY_SUBMIT", ekey };
    },
    ekeyComplete: () => {
        return { type: "ORDERS_EKEY_COMPLETE" };
    },
    ekeyError: error => {
        return { type: "ORDERS_EKEY_ERROR", error };
    },
    ekeySuccess: success => {
        return { type: "ORDERS_EKEY_SUCCESS", success };
    },
    ekeyClearform: () => {
        return { type: "ORDERS_EKEY_CLEARFORM" };
    }
};

//The adding threads are all controlled in arrays.
const init = {
    orders: [],
    page: 0,
    hasNext: null,
    isLoading: false,
    isLoadingMore: false
};

//Reducer
const ordersReducer = function (state = fromJS(init), action) {
    switch (action.type) {
        case "ORDERS_INIT":
            return state
                .set("pageSize", action.payload.Payload.PageSize)
                .set("itemId", action.payload.Payload.ItemId)
                .set("isLoading", true);

        case "ORDERS_LOAD_SUCCESS":
            return state
                .set("orders", action.payload.Items)
                .set("hasNext", action.payload.HasNext)
                .set("isLoading", false);

        case "ORDERS_LOAD_ERROR":
            return state.set("errorMessage", action.errorMessage).set("isLoading", false);

        case "ORDERS_LOAD_MORE": {
            const page = state.get("page");

            return state.set("page", page + 1).set("isLoadingMore", true);
        }

        case "ORDERS_LOAD_MORE_SUCCESS": {
            const orders = state.get("orders");

            return state
                .set("orders", orders.concat(action.payload.Items))
                .set("hasNext", action.payload.HasNext)
                .set("isLoadingMore", false);
        }

        case "ORDERS_EKEY_SUBMIT":
            return state.set("ekeyPending", true); //running saga

        case "ORDERS_EKEY_COMPLETE":
            return state.set("ekeyPending", false).set("ekeyComplete", true);

        case "ORDERS_EKEY_ERROR":
            return state.set("ekeyError", true);

        case "ORDERS_EKEY_SUCCESS":
            return state.set("ekeySuccess", true);

        case "ORDERS_EKEY_CLEARFORM":
            return state
                .set("ekeyPending", false)
                .set("ekeySuccess", false)
                .set("ekeyError", false)
                .set("ekeyComplete", false);

        default:
            return state;
    }
};

export const ordersSelector = {
    orders: state => state.orders.get("orders"),
    itemId: state => state.orders.get("itemId"),
    page: state => state.orders.get("page"),
    pageSize: state => state.orders.get("pageSize"),
    hasNext: state => state.orders.get("hasNext"),
    isLoading: state => state.orders.get("isLoading"),
    isLoadingMore: state => state.orders.get("isLoadingMore"),
    errorMessage: state => state.orders.get("errorMessage"),
    ekeyPending: state => state.orders.get("ekeyPending"),
    ekeySuccess: state => state.orders.get("ekeySuccess"),
    ekeyError: state => state.orders.get("ekeyError"),
    ekeyComplete: state => state.orders.get("ekeyComplete")
};

export default ordersReducer;
