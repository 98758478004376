import React, { Component } from "react";

class IconClose extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <svg x="0px" y="0px" width="50px" height="50px" viewBox="0 0 50 50">
                <line x1="16.6" y1="16.5" x2="33.4" y2="33.5" />
                <line x1="16.5" y1="33.4" x2="33.5" y2="16.6" />
            </svg>
        );
    }
}

export default IconClose;
