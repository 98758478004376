import { defineView } from "@/ReactSetup/view-definitions";
import { articleTeaserListActions } from "ReactReducers/ArticleTeaserList/article-teaser-list-reducer";

const definition = defineView({
    name: "ArticleFilter",
    component: () => (__SERVER__ ? require("./article-filter-view") : import("./article-filter-view")),
    setup: payload => {
        return articleTeaserListActions.init(payload);
    }
});

export default definition;
