import { fromJS } from "immutable";
import { urlParameters } from "ReactApi/urlParametersService";

export const productListActions = {
    setup: init => {
        return { type: "SETUP_PRODUCTLIST", init };
    },
    updateProductList: () => {
        return { type: "UPDATE_PRODUCTLIST" };
    },
    fetchProductList: () => {
        return { type: "FETCH_PRODUCTLIST" };
    },
    fetchProductListSuccess: list => {
        return { type: "FETCH_PRODUCTLIST_SUCCESS", list };
    },
    fetchProductListError: error => {
        return { type: "FETCH_PRODUCTLIST_ERROR", error };
    },
    fetchProductListComplete: () => {
        return { type: "FETCH_PRODUCTLIST_COMPLETE" };
    },
    openFacetOverlay: () => {
        return { type: "OPEN_FACET_OVERLAY" };
    },
    closeFacetOverlay: () => {
        return { type: "CLOSE_FACET_OVERLAY" };
    },
    productsInView: visibility => {
        return { type: "PRODUCTS_IN_VIEW", visibility };
    },
    selectFacets: id => {
        return { type: "SELECT_FACETS", id };
    },
    removeFacets: id => {
        return { type: "REMOVE_FACETS", id };
    },
    sort: (orderBy, orderDir) => {
        return { type: "PRODUCTLIST_SORT", orderBy, orderDir };
    },
    fetchMoreProducts: () => {
        return { type: "FETCH_MORE_PRODUCTS" };
    },
    fetchMoreProductsSuccess: products => {
        return { type: "FETCH_MORE_PRODUCTS_SUCCESS", products };
    },
    fetchMoreProductsComplete: () => {
        return { type: "FETCH_MORE_PRODUCTS_COMPLETE" };
    }
};

const initState = {
    isSetup: false,
    FacetGroupsActive: [],
    FacetGroups: [],
    Works: [],
    SortingOptions: [],
    labels: {
        FilteringHeadline: "action.init.Payload.FilteringHeadline",
        LoadMore: "action.init.Payload.LoadMore",
        LoadMoreRaw: "action.init.Payload.LoadMoreRaw",
        PrimaryPriceText: "action.init.Payload.PrimaryPriceText",
        SecondaryPriceText: "action.init.Payload.SecondaryPriceText",
        SortByHeadline: "action.init.Payload.SortByHeadline",
        NoResults: "action.init.Payload.NoResults",
        GenericSearchError: "action.init.Payload.GenericSearchError",
        ResetSearch: "action.init.Payload.ResetSearch",
        AddToBasket: "action.init.Payload.AddToCart",
        MaterialsText: "action.init.Payload.MaterialsText",
        ShowResultsButtonLabelText: "action.init.Payload.ShowResultsButtonLabelText",
        ResetFilterButtonLabelText: "action.init.Payload.ResetFilterButtonLabelText",
        VariantsLabel: "action.init.Payload.VariantsLabel",
        LoadingText: "action.init.Payload.LoadingText",
        EditorsListShortAbbreviation: "action.init.Payload.EditorsListShortAbbreviation"
    }
};

const getURLFacets = function getURLFacets() {
    const urlFacets = urlParameters.getParameterByName("facets");
    if (urlFacets) {
        const decodedFacets = JSON.parse(decodeURIComponent(urlFacets));

        return decodedFacets;
    } else {
        return [];
    }
};

const productList = function (state = fromJS(initState), action) {
    switch (action.type) {
        case "SETUP_PRODUCTLIST":
            return state
                .set("isSetup", true)
                .set("FacetConfigurationId", action.init.Payload.FacetConfigurationId)
                .set("ContextId", action.init.Payload.ContextId)
                .set("ListUrl", action.init.Payload.ListUrl)
                .set("InitialFilter", action.init.Payload.InitialFilter)
                .set("FacetGroupsActive", fromJS(getURLFacets()))
                .set("FacetGroups", fromJS(action.init.Payload.FacetGroups))
                .set("Works", fromJS(action.init.Payload.Works))
                .set("ErrorCode", fromJS(action.init.Payload.ErrorCode))
                .set("ErrorMessage", fromJS(action.init.Payload.ErrorMessage))
                .set("orderBy", action.init.Payload.OrderBy)
                .set("orderDir", action.init.Payload.OrderDir)
                .set("isFilterOverLayActive", false)
                .set("TotalResults", action.init.Payload.TotalResults)
                .set("HideFilters", action.init.Payload.HideFilters)
                .set("HideSortFilters", action.init.Payload.HideSortFilters)
                .set("HideTotalCount", action.init.Payload.HideTotalCount)
                .set("PageSize", action.init.Payload.PageSize)
                .set("ProductDeliveryInfo", action.init.Payload.ProductDeliveryInfo)
                .set("isLoading", false)
                .set("isLoadingMore", false)
                .set("SortingOptions", fromJS(action.init.Payload.OrderOptions || []))
                .set("CurrentPage", action.init.Payload.CurrentPage || 0)
                .set("labels", {
                    FilteringHeadline: action.init.Payload.FilteringHeadline,
                    LoadMore: action.init.Payload.LoadMore,
                    LoadMoreRaw: action.init.Payload.LoadMoreRaw,
                    PrimaryPriceText: action.init.Payload.PrimaryPriceText,
                    SecondaryPriceText: action.init.Payload.SecondaryPriceText,
                    SortByHeadline: action.init.Payload.SortByHeadline,
                    NoResults: action.init.Payload.NoResults,
                    GenericSearchError: action.init.Payload.GenericSearchError,
                    ResetSearch: action.init.Payload.ResetSearch,
                    AddToBasket: action.init.Payload.AddToCart,
                    MaterialsText: action.init.Payload.MaterialsText,
                    ShowResultsButtonLabelText: action.init.Payload.ShowResultsButtonLabelText,
                    ResetFilterButtonLabelText: action.init.Payload.ResetFilterButtonLabelText,
                    VariantsLabel: action.init.Payload.VariantsLabel,
                    LoadingText: action.init.Payload.LoadingText,
                    EditorsListShortAbbreviation: action.init.Payload.EditorsListShortAbbreviation
                })
                .set("isInView", false);

        case "SELECT_FACETS": {
            let updatedList = state.get("FacetGroupsActive");

            if (Array.isArray(action.id)) {
                action.id.map(id => {
                    if (updatedList.indexOf(id) == -1) {
                        updatedList = updatedList.push(id);
                    } else {
                        updatedList = updatedList.delete(updatedList.indexOf(id));
                    }
                });
            } else {
                if (updatedList.indexOf(action.id) == -1) {
                    updatedList = updatedList.push(action.id);
                } else {
                    updatedList = updatedList.delete(updatedList.indexOf(action.id));
                }
            }
            const JSupdatedList = updatedList.toJS();
            if (JSupdatedList.length >= 1) {
                urlParameters.setParameter("facets", encodeURIComponent(JSON.stringify(JSupdatedList)));
            } else {
                urlParameters.setParameter("facets");
            }
            urlParameters.replaceURL();

            return state.set("FacetGroupsActive", updatedList).set("CurrentPage", 0);
        }

        case "REMOVE_FACETS":
            urlParameters.setParameter("facets", null);
            urlParameters.replaceURL();

            return state.set("FacetGroupsActive", fromJS([]));

        case "FETCH_PRODUCTLIST":
            return state.set("isLoading", true);

        case "FETCH_PRODUCTLIST_SUCCESS": {
            const facetGroups = action.list.FacetGroups;

            return state
                .set("ContextId", action.list.ContextId)
                .set("ListUrl", action.list.ListUrl)
                .set("FacetConfigurationId", action.list.FacetConfigurationId)
                .set("FacetGroups", fromJS(facetGroups)) // .set('FacetGroups',fromJS(action.list.FacetGroups))
                .set("Works", fromJS(action.list.Works))
                .set("TotalResults", action.list.TotalResults)
                .set("ErrorMessage", "")
                .set("ErrorCode", 0)
                .set("CurrentPage", 0);
        }

        case "FETCH_PRODUCTLIST_ERROR":
            return state
                .set("ErrorMessage", fromJS(action.error.ErrorMessage))
                .set("ErrorCode", fromJS(action.error.ErrorCode));

        case "FETCH_PRODUCTLIST_COMPLETE":
            return state.set("isLoading", false);

        case "FETCH_MORE_PRODUCTS":
            var currentPage = state.get("CurrentPage");
            return state.set("isLoadingMore", true).set("CurrentPage", currentPage + 1);

        case "FETCH_MORE_PRODUCTS_COMPLETE":
            return state.set("isLoadingMore", false);

        case "FETCH_MORE_PRODUCTS_SUCCESS": {
            const list = state.get("Works").concat(fromJS(action.products));
            return state.set("Works", list);
        }

        case "OPEN_FACET_OVERLAY":
            return state.set("isFilterOverLayActive", true);

        case "CLOSE_FACET_OVERLAY":
            return state.set("isFilterOverLayActive", false);

        case "PRODUCTLIST_SORT":
            return state
                .set("CurrentPage", 0)
                .set("orderBy", action.orderBy)
                .set("orderDir", action.orderDir);

        case "PRODUCTS_IN_VIEW":
            return state.set("visibility", action.visibility);

        default:
            return state;
    }
};

export const productListSelector = {
    isSetup: state => state.productList.get("isSetup"),
    FacetGroups: state => state.productList.get("FacetGroups").toJS(),
    FacetGroupsActive: state => state.productList.get("FacetGroupsActive").toJS(),
    ListUrl: state => state.productList.get("ListUrl"),
    FacetConfigurationId: state => state.productList.get("FacetConfigurationId"),
    ContextId: state => state.productList.get("ContextId"),
    InitialFilter: state => state.productList.get("InitialFilter"),
    Works: state => state.productList.get("Works").toJS(),
    Labels: state => state.productList.get("labels"),
    TotalResults: state => state.productList.get("TotalResults"),
    HideFilters: state => state.productList.get("HideFilters"),
    HideSortFilters: state => state.productList.get("HideSortFilters"),
    HideTotalCount: state => state.productList.get("HideTotalCount"),
    PageSize: state => state.productList.get("PageSize"),
    isLoading: state => state.productList.get("isLoading"),
    isLoadingMore: state => state.productList.get("isLoadingMore"),
    isFilterOverLayActive: state => state.productList.get("isFilterOverLayActive"),
    SortingOptions: state => state.productList.get("SortingOptions").toJS(),
    Sorting: state => {
        return {
            orderBy: state.productList.get("orderBy"),
            orderDir: state.productList.get("orderDir")
        };
    },
    isFilterInView: state => state.productList.get("visibility"),
    visibility: state => state.productList.get("visibility"),
    CurrentPage: state => state.productList.get("CurrentPage"),
    ErrorMessage: state => state.productList.get("ErrorMessage"),
    ProductDeliveryInfo: state => state.productList.get("ProductDeliveryInfo")
};

export default productList;
